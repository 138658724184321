import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const EtisLogoFullTextLongTextEst: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 309,
  height = 60,
  fill = '#FFFFFF',
}): ReactElement => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 378">
      <path
        fill={fill}
        d="M586.94,31.1q-.63,7-.87,13.47t-.24,10q0,3.16.16,6c.1,1.9.21,3.48.32,4.75h-3.64q-1.59-11.56-4.92-18.3t-8.63-9.5a26.48,26.48,0,0,0-12.44-2.78h-6.49a23.08,23.08,0,0,0-7.05.8,4.75,4.75,0,0,0-3.09,3.09,24.54,24.54,0,0,0-.79,7.36v82.38a24.65,24.65,0,0,0,.79,7.28,4.74,4.74,0,0,0,3.09,3.17,22.76,22.76,0,0,0,7.05.8H558a25.13,25.13,0,0,0,12.59-3.09q5.48-3.09,9.35-10.54t5.94-20.12h3.65q-.48,5.07-.48,13.31,0,3.64.16,10.3t1,13.78q-8.08-.31-18.22-.4t-18.06-.08H539.58q-8.88,0-18.86.08t-18.53.4v-3.17a27.62,27.62,0,0,0,7.6-1.27,5.5,5.5,0,0,0,3.41-3.8,31.33,31.33,0,0,0,.87-8.55V47.89a31.26,31.26,0,0,0-.87-8.63,5.68,5.68,0,0,0-3.49-3.8,23.21,23.21,0,0,0-7.52-1.19V31.1q8.55.16,18.53.32t18.86.16h14.25q7.29,0,16.56-.08T586.94,31.1ZM562.23,85.28v3.17H534.5V85.28Zm4.59-20.91q-.63,9-.55,13.78t.08,8.71q0,4,.15,8.72t.8,13.78h-3.65a38,38,0,0,0-2.93-10.3,19,19,0,0,0-6.1-7.68q-4-2.92-10.29-2.93V85.28q6.33,0,10.14-3.49a23.9,23.9,0,0,0,5.86-8.31,44,44,0,0,0,2.85-9.11Z"
      />
      <path
        fill={fill}
        d="M641.91,59.14q13.94,0,21.86,8.24t7.92,26.93H619.57l-.31-3h32.47a76.52,76.52,0,0,0-1-14.34,30.5,30.5,0,0,0-3.48-10.53q-2.38-4-6.34-4-5.55,0-9.42,7t-4.68,23.13l.48,1q-.17,1.91-.24,4c-.06,1.37-.08,2.8-.08,4.28q0,10.92,3.09,17.74t7.84,9.9a17.56,17.56,0,0,0,9.66,3.09A26.78,26.78,0,0,0,658,130.11q5.63-2.37,10.54-10.3l2.85,1a41.29,41.29,0,0,1-6,11.73,32.87,32.87,0,0,1-10.45,9.34,30,30,0,0,1-15.21,3.65,38.3,38.3,0,0,1-19-4.6,31.56,31.56,0,0,1-12.91-13.94q-4.66-9.34-4.67-23.76,0-14.73,5-24.56A33.55,33.55,0,0,1,621.87,64,40.92,40.92,0,0,1,641.91,59.14Z"
      />
      <path
        fill={fill}
        d="M712.25,59.14a37.14,37.14,0,0,1,11.64,1.66,28.91,28.91,0,0,1,7.21,3.25q5.39,3.65,6.65-4.75h3.33c-.22,3-.37,6.57-.48,10.85s-.15,10-.15,17h-3.33a50.91,50.91,0,0,0-3-11.56,23.27,23.27,0,0,0-6.42-9.35,16,16,0,0,0-10.85-3.65,11.79,11.79,0,0,0-7.76,2.62c-2.12,1.74-3.17,4.25-3.17,7.53a12.93,12.93,0,0,0,2.85,8.31,36.77,36.77,0,0,0,7.37,6.81c3,2.17,6.15,4.46,9.42,6.9a94.59,94.59,0,0,1,13.39,11.79q5.46,6,5.46,15.45a19.75,19.75,0,0,1-4.11,12.44,26.51,26.51,0,0,1-10.86,8.15,37.84,37.84,0,0,1-15,2.86,34.53,34.53,0,0,1-8.47-.95,29.65,29.65,0,0,1-6.42-2.38c-1.37-.63-2.67-1.24-3.88-1.82s-2.35-1.08-3.41-1.51a2.44,2.44,0,0,0-2.77,1.11,11.62,11.62,0,0,0-1.82,4.44h-3.33q.32-5.07.48-12.36t.16-19.33h3.33a60.45,60.45,0,0,0,3.48,15.13,25.07,25.07,0,0,0,6.89,10.3,16.38,16.38,0,0,0,11,3.72,12.44,12.44,0,0,0,7.61-2.61q3.48-2.61,3.48-8.63A15.83,15.83,0,0,0,716.29,119a113.76,113.76,0,0,0-11.49-10q-5.07-4.11-9.58-8.16A37.39,37.39,0,0,1,687.85,92,21.25,21.25,0,0,1,685,81a19.06,19.06,0,0,1,3.8-12,23.07,23.07,0,0,1,10.06-7.36A36.24,36.24,0,0,1,712.25,59.14Z"
      />
      <path
        fill={fill}
        d="M786.22,35.38v26h18.7v3.17h-18.7v64c0,3.17.59,5.41,1.75,6.73a6.58,6.58,0,0,0,5.22,2,9.45,9.45,0,0,0,5.71-2.14q2.85-2.15,5.07-7.37l2.69,1.43A27.91,27.91,0,0,1,799,140.88q-5.15,4.6-14,4.6a28.46,28.46,0,0,1-9.19-1.35,16.61,16.61,0,0,1-11.25-12.67,65.65,65.65,0,0,1-1.11-13.39V64.53h-13.3V61.36h13.3V40.45a59.57,59.57,0,0,0,12.28-1.19A38.22,38.22,0,0,0,786.22,35.38Z"
      />
      <path
        fill={fill}
        d="M842.78,59.62v68.91c0,4.43.76,7.44,2.3,9s4.14,2.37,7.84,2.37v3.33q-2.85-.16-8.87-.4t-12.2-.24q-6.18,0-12.52.24t-9.5.4v-3.33c3.69,0,6.31-.79,7.84-2.37s2.29-4.6,2.29-9V78.62q0-7.12-2.13-10.53t-8-3.41V61.36q5.07.47,9.82.47,6.65,0,12.43-.55A86,86,0,0,0,842.78,59.62ZM830.42,20.49q6.66,0,10.53,3.24a11,11,0,0,1,3.89,9,11,11,0,0,1-3.89,9q-3.87,3.25-10.53,3.25t-10.53-3.25a11,11,0,0,1-3.88-9,11,11,0,0,1,3.88-8.95Q823.76,20.49,830.42,20.49Z"
      />
      <path
        fill={fill}
        d="M598.51,234.91q-.64,8.07-.87,15.52t-.24,11.41q0,3.48.16,6.65c.1,2.12.21,3.91.31,5.38h-3.64q-2.54-13.31-6.42-21.06t-9.27-11a24,24,0,0,0-12.67-3.25h-3.64V329q0,6.33,1.34,9.5a7.22,7.22,0,0,0,5,4.2,45.17,45.17,0,0,0,10.61,1.19v3.16q-4.92-.15-13.07-.31t-17-.16q-8.72,0-16.48.16t-12.51.31V343.9a46.11,46.11,0,0,0,10.77-1.19,7,7,0,0,0,4.91-4.2Q537,335.35,537,329V238.55h-3.49a24,24,0,0,0-12.67,3.25q-5.39,3.24-9.19,10.93T505,273.87H501.4a46.62,46.62,0,0,0,.39-5.38q.07-3.17.08-6.65,0-4-.24-11.41t-.87-15.52q7.29.15,15.92.31t17.35.16h31.44q8.64,0,17.35-.16T598.51,234.91Z"
      />
      <path
        fill={fill}
        d="M647.3,263q13.94,0,21.86,8.23t7.92,26.94H625l-.32-3h32.48a77.27,77.27,0,0,0-1-14.33,30.34,30.34,0,0,0-3.49-10.54q-2.37-4-6.33-4-5.55,0-9.43,7t-4.67,23.13l.47.95c-.1,1.27-.18,2.59-.23,4s-.08,2.8-.08,4.28q0,10.94,3.09,17.74c2,4.55,4.67,7.84,7.84,9.9a17.56,17.56,0,0,0,9.66,3.09,26.93,26.93,0,0,0,10.38-2.37q5.63-2.39,10.53-10.3l2.86.95a41.11,41.11,0,0,1-6,11.72,33,33,0,0,1-10.45,9.35,30,30,0,0,1-15.21,3.64,38.4,38.4,0,0,1-19-4.59,31.56,31.56,0,0,1-12.91-13.94q-4.68-9.35-4.68-23.76,0-14.75,5-24.56a33.39,33.39,0,0,1,13.78-14.65A40.92,40.92,0,0,1,647.3,263Z"
      />
      <path
        fill={fill}
        d="M707.18,348.17q-10.62,0-15.37-5.54a20,20,0,0,1-4.75-13.47q0-6.81,3.09-11.09a24.74,24.74,0,0,1,8-7.05,60,60,0,0,1,10.37-4.59q5.47-1.81,10.46-3.64a30.52,30.52,0,0,0,8.08-4.2,7.29,7.29,0,0,0,3.09-6V280.21a19,19,0,0,0-1.35-7.36,10.4,10.4,0,0,0-4.12-5,13.28,13.28,0,0,0-7.2-1.75,24.22,24.22,0,0,0-6.34.87,11.6,11.6,0,0,0-5.23,2.94,14.38,14.38,0,0,1,6.89,4.83,11.87,11.87,0,0,1,2.46,7.36,10.68,10.68,0,0,1-3.72,8.56,13.18,13.18,0,0,1-8.8,3.17q-5.7,0-8.63-3.57a13.12,13.12,0,0,1-2.93-8.63,12.21,12.21,0,0,1,2.29-7.61,25.85,25.85,0,0,1,6.42-5.7,38.91,38.91,0,0,1,10.7-4A60.83,60.83,0,0,1,724.13,263a53.17,53.17,0,0,1,13.15,1.5,21,21,0,0,1,9.82,5.47,17.37,17.37,0,0,1,4.75,8.95A67,67,0,0,1,753,292.25v43.09a13,13,0,0,0,.79,5.39,2.77,2.77,0,0,0,2.7,1.58,6.06,6.06,0,0,0,3-.79,15.5,15.5,0,0,0,2.7-1.9l1.58,2.69a23.35,23.35,0,0,1-7.68,4.36,29.77,29.77,0,0,1-9.74,1.5q-9.51,0-12.6-3.88a14.74,14.74,0,0,1-3.25-9,26.1,26.1,0,0,1-9.74,9.66A27.65,27.65,0,0,1,707.18,348.17ZM719.38,338a11.56,11.56,0,0,0,5.62-1.42,16,16,0,0,0,5.15-5.07V299.07a16,16,0,0,1-5.31,5.3q-3.4,2.14-7,4.59a21.16,21.16,0,0,0-5.94,6.26q-2.37,3.81-2.38,10.14,0,6.81,2.86,9.75A9.35,9.35,0,0,0,719.38,338Z"
      />
      <path
        fill={fill}
        d="M805.87,263a30.71,30.71,0,0,1,12.28,2.29,17.31,17.31,0,0,1,8.32,7.68l-1.91,1.75a12.38,12.38,0,0,0-5.46-5.47,16.22,16.22,0,0,0-7.21-1.66q-8.08,0-12.83,9.43T794.46,306q0,13.45,1.83,21.46T801.51,339a10.67,10.67,0,0,0,7.85,3.49,13.32,13.32,0,0,0,9.66-4.28q4.28-4.28,4.59-11.56l.8,5.54q-2.38,8.4-7.69,12.76t-13.86,4.35a30.94,30.94,0,0,1-16.71-4.59q-7.36-4.59-11.49-14.18t-4.12-24.79q0-14.42,4.6-23.84a32.48,32.48,0,0,1,12.59-14.18A34.81,34.81,0,0,1,805.87,263ZM846.27,223V329.64q0,7.13,2.22,10.53c1.47,2.28,4.11,3.41,7.92,3.41v3.33q-4.92-.48-9.83-.48-6.65,0-12.43.48a62.61,62.61,0,0,0-10.7,1.74V242q0-7.12-2.13-10.53t-8-3.41v-3.32q5.07.47,9.82.47,6.5,0,12.35-.55A85.8,85.8,0,0,0,846.27,223Z"
      />
      <path
        fill={fill}
        d="M942,263.42V329.8q0,7.13,2.22,10.53c1.48,2.28,4.12,3.41,7.92,3.41v3.32q-4.92-.46-9.82-.47-6.66,0-12.36.47a67.28,67.28,0,0,0-10.77,1.75V334.55q-3.81,7.92-10.54,11.33a32.7,32.7,0,0,1-15,3.4,28,28,0,0,1-10.77-1.74,17.63,17.63,0,0,1-6.5-4.44,17.37,17.37,0,0,1-3.8-7.92,59.13,59.13,0,0,1-1.11-12.67V282.43q0-7.12-2.13-10.53t-8-3.41v-3.33c3.37.32,6.65.48,9.82.48q6.65,0,12.43-.56a84.13,84.13,0,0,0,10.7-1.66V329a30.21,30.21,0,0,0,.71,7,8.22,8.22,0,0,0,2.77,4.67q2.06,1.67,6,1.66A13.79,13.79,0,0,0,914.62,337a19.46,19.46,0,0,0,4.52-13.07v-41.5q0-7.12-2.14-10.53t-8-3.41v-3.33q5.07.48,9.82.48,6.66,0,12.44-.56A84,84,0,0,0,942,263.42Z"
      />
      <path
        fill={fill}
        d="M990.43,263a36.85,36.85,0,0,1,11.64,1.66,28.55,28.55,0,0,1,7.21,3.25q5.38,3.64,6.65-4.76h3.33c-.22,3-.37,6.58-.48,10.85s-.16,10-.16,17h-3.32a51,51,0,0,0-3-11.57,23.31,23.31,0,0,0-6.42-9.34A15.9,15.9,0,0,0,995,266.43a11.74,11.74,0,0,0-7.76,2.62c-2.12,1.74-3.17,4.25-3.17,7.52a12.94,12.94,0,0,0,2.85,8.32,37.36,37.36,0,0,0,7.37,6.81q4.52,3.24,9.42,6.89a94.62,94.62,0,0,1,13.39,11.8q5.46,5.94,5.46,15.45a19.7,19.7,0,0,1-4.11,12.43,26.53,26.53,0,0,1-10.86,8.16,38,38,0,0,1-15,2.85,34.06,34.06,0,0,1-8.47-.95,28.46,28.46,0,0,1-6.42-2.38c-1.37-.63-2.67-1.23-3.88-1.82s-2.35-1.08-3.41-1.5a2.42,2.42,0,0,0-2.77,1.11,11.5,11.5,0,0,0-1.82,4.43h-3.33q.32-5.07.48-12.35t.16-19.33h3.32A61,61,0,0,0,970,331.62a25.2,25.2,0,0,0,6.89,10.3,16.36,16.36,0,0,0,11,3.72,12.39,12.39,0,0,0,7.6-2.61c2.33-1.75,3.49-4.62,3.49-8.64a15.79,15.79,0,0,0-4.52-11.56,113.53,113.53,0,0,0-11.48-10q-5.07-4.12-9.59-8.16a37.78,37.78,0,0,1-7.36-8.87,21.25,21.25,0,0,1-2.85-11,19,19,0,0,1,3.8-12A23.17,23.17,0,0,1,977,265.4,36.44,36.44,0,0,1,990.43,263Z"
      />
      <path
        fill={fill}
        d="M1065.19,263.42v68.91q0,6.66,2.3,9t7.85,2.38v3.32c-1.91-.1-4.86-.23-8.88-.39s-8.08-.24-12.19-.24-8.3.08-12.52.24-7.39.29-9.5.39v-3.32c3.69,0,6.31-.79,7.84-2.38s2.29-4.59,2.29-9v-49.9q0-7.12-2.13-10.53t-8-3.41v-3.33c3.37.32,6.65.48,9.82.48q6.65,0,12.43-.56A83.68,83.68,0,0,0,1065.19,263.42Zm-12.35-39.13c4.43,0,8,1.09,10.53,3.25a12.24,12.24,0,0,1,0,17.9c-2.58,2.17-6.1,3.25-10.53,3.25s-7.95-1.08-10.54-3.25a12.26,12.26,0,0,1,0-17.9Q1046.19,224.3,1052.84,224.29Z"
      />
      <path
        fill={fill}
        d="M1141.07,263a29.87,29.87,0,0,1,10.86,1.66,16,16,0,0,1,6.57,4.52,18.92,18.92,0,0,1,3.72,7.76,55.08,55.08,0,0,1,1.19,12.83v42.61q0,6.66,2.3,9t7.84,2.38v3.32q-3-.15-9-.39c-4-.16-8-.24-11.88-.24s-8,.08-11.81.24-6.78.29-8.79.39v-3.32q4.76,0,6.66-2.38c1.26-1.58,1.9-4.59,1.9-9V283.07a27.06,27.06,0,0,0-.79-6.9,8.62,8.62,0,0,0-2.85-4.59q-2.07-1.66-6.18-1.66a14.19,14.19,0,0,0-11.17,5.22,19.42,19.42,0,0,0-4.52,13.15v44q0,6.66,2,9t6.58,2.38v3.32c-1.9-.1-4.68-.23-8.32-.39s-7.42-.24-11.33-.24-8.13.08-12.35.24-7.4.29-9.51.39v-3.32q5.55,0,7.84-2.38t2.3-9v-49.9q0-7.12-2.14-10.53t-8-3.41v-3.33c3.38.32,6.66.48,9.82.48q6.66,0,12.44-.56a84,84,0,0,0,10.69-1.66v14.26q4-8.08,10.77-11.41A34.22,34.22,0,0,1,1141.07,263Z"
      />
      <path
        fill={fill}
        d="M1221.23,223.18a29.62,29.62,0,0,1,9.67,1.43,26.59,26.59,0,0,1,7.28,3.8,14.2,14.2,0,0,1,3.8,4.83,15.19,15.19,0,0,1,1.59,7,10.88,10.88,0,0,1-3.09,7.61,10.63,10.63,0,0,1-8.16,3.32,12.54,12.54,0,0,1-8.63-3,10.42,10.42,0,0,1-3.41-8.23,11.78,11.78,0,0,1,2.38-7.29,12.54,12.54,0,0,1,7-4.44,3.67,3.67,0,0,0-1.9-1.58,9.55,9.55,0,0,0-3.8-.63,11.05,11.05,0,0,0-5.23,1.1,9.2,9.2,0,0,0-3.33,3A15.42,15.42,0,0,0,1213,237a110.32,110.32,0,0,0-.63,13.87v14.41h17v3.17h-17v62.26q0,7.75,4,10.38t10.93,2.61v3.32l-11.56-.39q-7.45-.24-15.69-.24-6,0-11.8.24c-3.85.16-6.79.29-8.79.39v-3.32c3.69,0,6.31-.79,7.84-2.38s2.3-4.59,2.3-9V268.49h-10.77v-3.17h10.77q0-14.1,2.22-21.46a29.33,29.33,0,0,1,7.76-12.76,28.52,28.52,0,0,1,9.27-5.62A33.38,33.38,0,0,1,1221.23,223.18Z"
      />
      <path
        fill={fill}
        d="M1276.05,263a45.51,45.51,0,0,1,20.27,4.27A30.32,30.32,0,0,1,1310,281q4.9,9.51,4.91,25.19T1310,331.3a30.27,30.27,0,0,1-13.63,13.7,49.62,49.62,0,0,1-40.32,0,30.58,30.58,0,0,1-13.78-13.7q-5-9.42-5-25.11t5-25.19A30.62,30.62,0,0,1,1256,267.22,45.29,45.29,0,0,1,1276.05,263Zm0,3.16q-6.35,0-10.62,9.43t-4.28,30.65q0,21.24,4.28,30.58t10.62,9.34q6.5,0,10.69-9.34t4.2-30.58q0-21.22-4.2-30.65T1276.05,266.11Z"
      />
      <path
        fill={fill}
        d="M1356,263a36.87,36.87,0,0,1,11.65,1.66,28.72,28.72,0,0,1,7.21,3.25q5.38,3.64,6.65-4.76h3.32q-.31,4.44-.47,10.85t-.16,17h-3.32a50.5,50.5,0,0,0-3-11.57,23.29,23.29,0,0,0-6.41-9.34,15.92,15.92,0,0,0-10.85-3.65,11.74,11.74,0,0,0-7.76,2.62c-2.12,1.74-3.17,4.25-3.17,7.52a12.94,12.94,0,0,0,2.85,8.32,37.08,37.08,0,0,0,7.37,6.81c3,2.16,6.15,4.46,9.42,6.89a95.18,95.18,0,0,1,13.39,11.8q5.46,5.94,5.46,15.45a19.65,19.65,0,0,1-4.12,12.43,26.43,26.43,0,0,1-10.85,8.16,38,38,0,0,1-15,2.85,34,34,0,0,1-8.47-.95,28.28,28.28,0,0,1-6.42-2.38c-1.37-.63-2.67-1.23-3.88-1.82s-2.35-1.08-3.41-1.5a2.42,2.42,0,0,0-2.77,1.11,11.5,11.5,0,0,0-1.82,4.43h-3.33q.31-5.07.48-12.35t.16-19.33h3.32a61,61,0,0,0,3.49,15.13,25.11,25.11,0,0,0,6.89,10.3,16.34,16.34,0,0,0,11,3.72,12.41,12.41,0,0,0,7.6-2.61c2.33-1.75,3.49-4.62,3.49-8.64a15.83,15.83,0,0,0-4.52-11.56,114.65,114.65,0,0,0-11.48-10q-5.07-4.12-9.59-8.16a37.54,37.54,0,0,1-7.36-8.87,21.25,21.25,0,0,1-2.85-11,19,19,0,0,1,3.8-12,23.06,23.06,0,0,1,10.06-7.37A36.43,36.43,0,0,1,1356,263Z"
      />
      <path
        fill={fill}
        d="M1476.75,263.42V329.8q0,7.13,2.22,10.53c1.48,2.28,4.12,3.41,7.93,3.41v3.32q-4.92-.46-9.83-.47-6.64,0-12.35.47a67.23,67.23,0,0,0-10.78,1.75V334.55q-3.8,7.92-10.53,11.33a32.7,32.7,0,0,1-15,3.4,28,28,0,0,1-10.77-1.74,17.55,17.55,0,0,1-6.5-4.44,17.27,17.27,0,0,1-3.8-7.92,59.13,59.13,0,0,1-1.11-12.67V282.43q0-7.12-2.14-10.53t-8-3.41v-3.33q5.07.48,9.82.48,6.66,0,12.44-.56a84,84,0,0,0,10.69-1.66V329a30.17,30.17,0,0,0,.72,7,8.17,8.17,0,0,0,2.77,4.67q2.06,1.67,6,1.66a13.79,13.79,0,0,0,10.85-5.31,19.45,19.45,0,0,0,4.51-13.07v-41.5q0-7.12-2.13-10.53t-8-3.41v-3.33c3.37.32,6.65.48,9.82.48q6.64,0,12.43-.56A83.68,83.68,0,0,0,1476.75,263.42Zm-54.81-36.75a11.69,11.69,0,0,1,8.4,3.56,11.42,11.42,0,0,1,0,16.72,11.81,11.81,0,0,1-8.4,3.48,11.82,11.82,0,0,1-8.39-20.2A11.32,11.32,0,0,1,1421.94,226.67Zm36.12,0a11.82,11.82,0,0,1,8.32,20.28,11.54,11.54,0,0,1-8.32,3.48,11.7,11.7,0,0,1-8.47-3.48,11.57,11.57,0,0,1,0-16.72A11.58,11.58,0,0,1,1458.06,226.67Z"
      />
      <path
        fill={fill}
        d="M1525.23,263a36.81,36.81,0,0,1,11.64,1.66,28.55,28.55,0,0,1,7.21,3.25q5.39,3.64,6.65-4.76h3.33q-.32,4.44-.48,10.85t-.15,17h-3.33a50.48,50.48,0,0,0-3-11.57,23.31,23.31,0,0,0-6.42-9.34,15.9,15.9,0,0,0-10.85-3.65,11.75,11.75,0,0,0-7.76,2.62,9.2,9.2,0,0,0-3.17,7.52,12.94,12.94,0,0,0,2.85,8.32,37.36,37.36,0,0,0,7.37,6.81q4.52,3.24,9.42,6.89a94.07,94.07,0,0,1,13.39,11.8q5.47,5.94,5.47,15.45a19.71,19.71,0,0,1-4.12,12.43,26.57,26.57,0,0,1-10.85,8.16,38,38,0,0,1-15,2.85,34.08,34.08,0,0,1-8.48-.95,28.37,28.37,0,0,1-6.41-2.38c-1.38-.63-2.67-1.23-3.89-1.82s-2.35-1.08-3.4-1.5a2.43,2.43,0,0,0-2.78,1.11,11.5,11.5,0,0,0-1.82,4.43h-3.32q.32-5.07.47-12.35t.16-19.33h3.33a60.55,60.55,0,0,0,3.48,15.13,25.2,25.2,0,0,0,6.89,10.3,16.38,16.38,0,0,0,11,3.72,12.44,12.44,0,0,0,7.61-2.61c2.32-1.75,3.48-4.62,3.48-8.64a15.82,15.82,0,0,0-4.51-11.56,113.76,113.76,0,0,0-11.49-10q-5.07-4.12-9.58-8.16a37.58,37.58,0,0,1-7.37-8.87,21.25,21.25,0,0,1-2.85-11,19.08,19.08,0,0,1,3.8-12,23.17,23.17,0,0,1,10.06-7.37A36.48,36.48,0,0,1,1525.23,263Z"
      />
      <path
        fill={fill}
        d="M1599.21,239.18v26h18.69v3.17h-18.69v64c0,3.17.58,5.42,1.74,6.73a6.56,6.56,0,0,0,5.23,2,9.46,9.46,0,0,0,5.7-2.15q2.85-2.13,5.07-7.36l2.69,1.43a27.91,27.91,0,0,1-7.68,11.72q-5.14,4.59-14,4.59a28.75,28.75,0,0,1-9.19-1.34,16.57,16.57,0,0,1-11.24-12.68,65.54,65.54,0,0,1-1.11-13.38V268.33h-13.31v-3.17h13.31V244.25a58.86,58.86,0,0,0,12.27-1.19A38.08,38.08,0,0,0,1599.21,239.18Z"
      />
      <path
        fill={fill}
        d="M1663.69,263q13.92,0,21.86,8.23t7.92,26.94h-52.12l-.32-3h32.48a78.14,78.14,0,0,0-1-14.33,30.34,30.34,0,0,0-3.49-10.54q-2.38-4-6.34-4c-3.7,0-6.83,2.33-9.42,7s-4.15,12.36-4.68,23.13l.48.95q-.16,1.9-.24,4c0,1.37-.08,2.8-.08,4.28q0,10.94,3.09,17.74c2.06,4.55,4.68,7.84,7.84,9.9a17.56,17.56,0,0,0,9.67,3.09,26.89,26.89,0,0,0,10.37-2.37q5.63-2.39,10.54-10.3l2.85.95a41.33,41.33,0,0,1-6,11.72,33.05,33.05,0,0,1-10.46,9.35,30,30,0,0,1-15.21,3.64,38.36,38.36,0,0,1-19-4.59,31.58,31.58,0,0,1-12.92-13.94q-4.66-9.35-4.67-23.76,0-14.75,5-24.56a33.45,33.45,0,0,1,13.78-14.65A41,41,0,0,1,1663.69,263Z"
      />
      <path
        fill={fill}
        d="M1743.53,263q13.93,0,21.86,8.23t7.92,26.94h-52.12l-.32-3h32.48a78.14,78.14,0,0,0-.95-14.33,30.34,30.34,0,0,0-3.49-10.54c-1.58-2.64-3.7-4-6.33-4q-5.55,0-9.43,7t-4.67,23.13l.47.95q-.17,1.9-.24,4c0,1.37-.08,2.8-.08,4.28q0,10.94,3.09,17.74c2.06,4.55,4.68,7.84,7.84,9.9a17.56,17.56,0,0,0,9.67,3.09,26.85,26.85,0,0,0,10.37-2.37q5.63-2.39,10.54-10.3l2.85.95a41.07,41.07,0,0,1-6,11.72,33.12,33.12,0,0,1-10.45,9.35,30,30,0,0,1-15.21,3.64,38.37,38.37,0,0,1-19-4.59,31.58,31.58,0,0,1-12.92-13.94q-4.67-9.35-4.67-23.76,0-14.75,5-24.56a33.45,33.45,0,0,1,13.78-14.65A41,41,0,0,1,1743.53,263Z"
      />
      <path
        fill={fill}
        d="M1840,263a29.13,29.13,0,0,1,10.77,1.66,17.39,17.39,0,0,1,6.66,4.52,18.36,18.36,0,0,1,3.64,7.84,59,59,0,0,1,1.11,12.75v42.61c0,4.44.68,7.45,2.06,9s3.74,2.38,7.13,2.38v3.32c-1.91-.1-4.78-.23-8.64-.39s-7.63-.24-11.32-.24-7.56.08-11.25.24-6.5.29-8.4.39v-3.32q4.27,0,5.94-2.38t1.66-9V283.07a33.26,33.26,0,0,0-.63-6.9,8.44,8.44,0,0,0-2.45-4.59,7.88,7.88,0,0,0-5.47-1.66,13.22,13.22,0,0,0-7.6,2.37,17.19,17.19,0,0,0-5.63,6.58,20.5,20.5,0,0,0-2.14,9.42v44q0,6.66,1.75,9t5.86,2.38v3.32c-1.69-.1-4.31-.23-7.84-.39s-7.16-.24-10.85-.24q-6,0-12.36.24c-4.23.16-7.39.29-9.51.39v-3.32c3.7,0,6.31-.79,7.85-2.38s2.29-4.59,2.29-9v-49.9q0-7.12-2.14-10.53t-8-3.41v-3.33q5.07.48,9.83.48,6.65,0,12.43-.56a84,84,0,0,0,10.69-1.66v14.26q3.81-7.92,10.3-11.33A30.23,30.23,0,0,1,1840,263Zm47.68,0a29.23,29.23,0,0,1,10.78,1.66,17.42,17.42,0,0,1,6.65,4.52,18.24,18.24,0,0,1,3.64,7.84,59,59,0,0,1,1.11,12.75v42.61c0,4.44.76,7.45,2.3,9s4.14,2.38,7.84,2.38v3.32q-3-.15-9-.39c-4-.16-8-.24-11.88-.24s-7.55.08-11.25.24-6.49.29-8.39.39v-3.32q4.27,0,5.94-2.38t1.66-9V283.07a29.82,29.82,0,0,0-.71-6.9,8.42,8.42,0,0,0-2.7-4.59q-2-1.66-6.09-1.66-6.18,0-10.78,5.46a20.77,20.77,0,0,0-4.75,13.39l-.79-9.67q4.27-9.5,11.32-12.83A35,35,0,0,1,1887.68,263Z"
      />
      <rect
        x="198.06"
        y="9.37"
        width="44.51"
        height="44.51"
        transform="translate(42.16 165.05) rotate(-45)"
        fill={fill}
      />
      <rect
        x="135.11"
        y="9.37"
        width="44.51"
        height="44.51"
        transform="translate(23.73 120.54) rotate(-45)"
        fill={fill}
      />
      <rect
        x="323.96"
        y="135.27"
        width="44.51"
        height="44.51"
        transform="translate(-9.98 290.95) rotate(-45)"
        fill={fill}
      />
      <rect
        x="323.96"
        y="198.22"
        width="44.51"
        height="44.51"
        transform="translate(-54.49 309.39) rotate(-45)"
        fill={fill}
      />
      <rect
        x="9.22"
        y="198.22"
        width="44.51"
        height="44.51"
        transform="translate(-146.68 86.83) rotate(-45)"
        fill={fill}
      />
      <rect
        x="9.22"
        y="135.27"
        width="44.51"
        height="44.51"
        transform="translate(-102.17 68.39) rotate(-45)"
        fill={fill}
      />
      <rect
        x="135.11"
        y="324.11"
        width="44.51"
        height="44.51"
        transform="translate(-198.83 212.73) rotate(-45)"
        fill={fill}
      />
      <rect
        x="198.06"
        y="324.11"
        width="44.51"
        height="44.51"
        transform="translate(-180.39 257.24) rotate(-45)"
        fill={fill}
      />
      <path
        fill={fill}
        d="M314.74,283.42,283.27,252l-.14.14V125.91l.14.14,31.47-31.47L346.21,63.1,314.74,31.63,283.27,63.1,251.79,94.58l0,0H125.88l0,0L94.42,63.1,63,31.63,31.47,63.1,63,94.58l31.47,31.47.08-.07V252l-.08-.07L63,283.42,31.47,314.9,63,346.37,94.42,314.9l31.48-31.48-.2-.19H252l-.2.19,31.48,31.48,31.47,31.47,31.47-31.47ZM132.39,245.34V132.49H245.23V245.34Z"
      />
      <polygon
        fill={fill}
        points="211.07 166.66 166.56 166.66 166.56 211.17 166.59 211.17 166.59 211.26 211.1 211.26 211.1 166.74 211.07 166.74 211.07 166.66"
      />
    </svg>
  );
};

export default EtisLogoFullTextLongTextEst;

import React, { Suspense, FC, ReactElement, useState, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { generateKey } from 'shared/functions/generateKey';
import {
  Page404Component,
  redirectBaseUrl,
  routePowerBi,
  routeProjects,
  RouteProps,
  routes,
} from 'routes';
import Box from '@material-ui/core/Box';
import { GlobalData } from 'shared/types/globalData';
import { BaseProps } from 'shared/types';
import { Loader, Header, Footer } from 'components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { CookiesPrivacyModal } from 'components/CookiesPrivacy/CookiesPrivacyModal';
import CookieBanner from 'react-cookie-banner';
import { Helmet } from 'react-helmet';

const RouteLoadingFallback = (
  <Box height="100vh" width="100vw">
    <Loader cover noBackground color="secondary" />
  </Box>
);

const defaultGlobalData: GlobalData = {
  countries: [],
  characterization: [],
};

export const GlobalDataContext = React.createContext<GlobalData>(defaultGlobalData);

const PortalApp: FC<BaseProps> = (): ReactElement => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const currentLanguageCode = i18n.language;

  useEffect(() => {
    document.documentElement.lang = currentLanguageCode;
  }, [currentLanguageCode]);

  const [globalData] = useState<GlobalData>(defaultGlobalData);

  const routeMapper = (
    { path, component, exact, environment, ...rest }: RouteProps,
    index: number,
  ): ReactElement =>
    component && (
      <Route
        key={generateKey(index, (path.length ? path[0] : path).replace('/', '-'))}
        path={path}
        component={component}
        exact={exact}
        {...rest}
      />
    );

  const renderRoutes = (
    <Suspense fallback={RouteLoadingFallback}>
      <Switch>
        {routes.map(routeMapper)}
        <Redirect to={`${routeProjects}/?9=5__AND`} from={redirectBaseUrl} />
        <Redirect to={`${routePowerBi}`} from="/Portal/ResearchStatistics/Graph" />
        <Redirect to={`${routePowerBi}`} from="/teadussilm" />
        <Route key="error404" path="*" component={Page404Component} exact />
      </Switch>
    </Suspense>
  );

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };
  const showCookieModal = location.pathname?.indexOf('TermOfUse') === -1;
  return (
    <>
      <Helmet>
        {currentLanguageCode === 'en' && (
          <meta
            name="description"
            content="Estonian Research Information System collects information about Estonian researchers, institutions, projects, publications and the results of other research activities."
          />
        )}
      </Helmet>
      <CookieBanner cookie="user-has-accepted-cookies" cookiePath="/" dismissOnScroll={false}>
        {(onAccept: any, onReject: any) => (
          <CookiesPrivacyModal
            onAccept={onAccept}
            onReject={onReject}
            showExportModal={showCookieModal}
          />
        )}
      </CookieBanner>
      <div className={clsx('container page-wrapper main-page')}>
        <Header onLanguageChange={onLanguageChange} />
        <main>
          <GlobalDataContext.Provider value={globalData}>{renderRoutes}</GlobalDataContext.Provider>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default PortalApp;

import React, { FC, ReactElement } from 'react';
import PlanetImage from 'assets/images/planet.svg';
import Navbar from 'components/Navbar/Navbar';
import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Language, languages } from 'shared/constants/languages';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DoneIcon from 'components/Icons/Done/DoneIcon';
import EtisLogoPositive from 'components/Icons/Logo/EtisLogoPositive';
import MenuHamburgerIcon from 'components/Icons/MenuHamburger/MenuHamburgerIcon';
import ClearIcon from 'components/Icons/Clear/ClearIcon';

type HeaderProps = {
  onLanguageChange: (language: string) => void;
};

const Header: FC<HeaderProps> = ({ onLanguageChange }): ReactElement => {
  const [languageSwitchOpened, setLanguageSwitchOpened] = React.useState(false);
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const { i18n, t } = useTranslation();

  const changeLanguage = (code: string) => {
    onLanguageChange(code);
    setLanguageSwitchOpened(false);
    try {
      if (window.location.pathname && window.location.pathname.indexOf('CV') > 0) {
        if (code === 'et') {
          window.location.replace(window.location.href.replace('/eng', '/est'));
        }
        if (code === 'en') {
          window.location.replace(window.location.href.replace('/est', '/eng'));
        }
      }
    } catch (e) {
      console.log(e);
    }
    setTimeout(() => {
      window.location.reload();
    }, 700);
  };

  const currentLanguageCode = i18n.language;
  const currentLanguage = languages.filter(({ code }) => code === currentLanguageCode);

  const openMobile = () => {
    if (!mobileNavOpen) {
      document.body.classList.add('mobile-menu-open');
    } else {
      document.body.classList.remove('mobile-menu-open');
    }

    setMobileNavOpen(!mobileNavOpen);
  };

  const languageLinkHtml = (language: Language, hasChildren?: boolean) => (
    <span
      key={language.code}
      role="menuitem"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => changeLanguage(language.code)}
      className={clsx(
        'nav-link link-dark pointer language-item',
        language.code === currentLanguageCode && 'active',
      )}
      aria-label={language.longLabel}
    >
      {language.longLabel}{' '}
      {language.code === currentLanguageCode && <DoneIcon fill="#2343E6" width={24} height={24} />}
    </span>
  );

  const languageSwitcher = () => (
    <Dropdown
      isOpen={languageSwitchOpened}
      toggle={() => setLanguageSwitchOpened(!languageSwitchOpened)}
      className="language-switcher"
    >
      <DropdownToggle
        caret
        nav
        aria-label="Change language"
        className="nav-link link-dark dropdown-toggle language-toggle"
      >
        <span className="language-switcher d-flex">
          <img src={PlanetImage} alt="Language" />
          <span className="m-l-4">{currentLanguage[0].label}</span>
        </span>
      </DropdownToggle>
      <DropdownMenu right tabIndex={0}>
        {languages.map((language, index) => {
          return languageLinkHtml(language);
        })}
      </DropdownMenu>
    </Dropdown>
  );
  return (
    <>
      <header>
        <div className="mobile-nav d-flex  align-items-center d-lg-none">
          <button onClick={openMobile} type="button" onKeyDown={() => {}}>
            {!mobileNavOpen && <MenuHamburgerIcon />}
          </button>
        </div>
        <div
          className={clsx(
            'mobile-nav-items',
            !mobileNavOpen && 'd-none',
            mobileNavOpen && 'd-block',
          )}
        >
          <button onClick={openMobile} type="button" onKeyDown={() => {}}>
            {mobileNavOpen && <ClearIcon fill="#2343E6" width={24} height={24} />}
          </button>
          <Navbar />
          {mobileNavOpen && languageSwitcher()}
        </div>
        <NavLink exact to="/" className="logo" tabIndex={-1}>
          <EtisLogoPositive />
        </NavLink>
        <div className="d-none d-lg-flex flex-wrap align-items-center justify-content-center">
          <Navbar />
        </div>
        <div className="left-menu">
          <span className=" d-none d-lg-flex">{!mobileNavOpen && languageSwitcher()}</span>

          <a href="/Logon" className="btn btn-secondary btn-login" tabIndex={0}>
            <strong>{t('BtnNavbarEnter')}</strong>
          </a>
        </div>
      </header>
    </>
  );
};
export default Header;

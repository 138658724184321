import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const PoiIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 24,
  height = 24,
  fill = '#000000',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="OVERVIEW" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Icons"
          transform="translate(-340.000000, -1300.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="ico-/-24-/-map-&amp;-places-/-poi" transform="translate(340.000000, 1300.000000)">
            <path
              d="M12,2 C7.581722,2 4,5.581722 4,10 C4,16.22 9.05,16.17 10.26,19.78 L10.77,21.32 C10.9129939,21.7453709 11.3218476,22.0233915 11.77,22 L12.33,22 C12.7781524,22.0233915 13.1870061,21.7453709 13.33,21.32 L13.84,19.78 C15,16.17 20,16.22 20,10 C20,5.581722 16.418278,2 12,2 Z M12,13 C10.3431458,13 9,11.6568542 9,10 C9,8.34314575 10.3431458,7 12,7 C13.6568542,7 15,8.34314575 15,10 C15,10.7956495 14.6839295,11.5587112 14.1213203,12.1213203 C13.5587112,12.6839295 12.7956495,13 12,13 Z"
              id="Icon-color"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PoiIcon;

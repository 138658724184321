import { Loader, FullPageLoader } from './Loader/Loader';
import EnvironmentPanel from './EnvironmentPanel/EnvironmentPanel';
import Header from './Header/Header';
import Footer from './Footer/Footer';

// NB. some users open wrong/short aadress etis.ee, which gets CORS errors.
// so redirect those to correct www.etis.ee
if (window.location.href.startsWith('https://etis.ee')) {
  window.location.replace(window.location.href.replace('https://etis.ee', 'https://www.etis.ee'));
}

export { Loader, FullPageLoader, EnvironmentPanel, Header, Footer };

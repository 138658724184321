import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const EtagLogo: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 300,
  height = 90,
  fill = '#6638b6',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 530.21 165.86"
    >
      <polygon
        fill={fill}
        points="105.42 52.7 105.42 58.36 118.16 51 118.16 61.62 127.36 66.94 105.42 79.61 105.42 85.26 137.16 66.94 123.06 58.8 123.06 42.52 105.42 52.7"
      />
      <circle fill={fill} cx="67.12" cy="38.82" r="4.98" />
      <polygon
        fill={fill}
        points="94.78 49.92 94.78 58.84 94.78 64.5 94.78 89.72 99.69 92.55 99.69 91.38 99.68 91.38 99.68 82.92 99.68 61.67 99.68 56.02 99.68 49.92 99.68 36.37 78.37 36.37 78.37 41.27 94.78 41.27 94.78 49.92"
      />
      <circle fill={fill} cx="58.79" cy="117.22" r="4.98" />
      <polygon
        fill={fill}
        points="54.58 87.72 62.3 83.26 67.21 80.43 89.04 67.82 89.04 62.16 88.03 62.74 88.03 62.74 80.71 66.97 62.31 77.6 57.41 80.43 52.13 83.48 40.39 90.25 51.04 108.7 55.29 106.25 47.08 92.05 54.58 87.72"
      />
      <circle fill={fill} cx="130.84" cy="85.23" r="4.98" />
      <polygon
        fill={fill}
        points="107.39 103.63 99.66 99.17 94.76 96.34 72.93 83.73 68.02 86.56 69.03 87.15 69.03 87.14 76.36 91.37 94.76 102 99.66 104.83 104.94 107.87 116.68 114.65 127.33 96.2 123.09 93.75 114.89 107.95 107.39 103.63"
      />
      <polygon
        fill={fill}
        points="94.79 108.65 94.79 123.36 85.59 118.05 76.39 123.36 76.39 98.03 71.49 95.2 71.49 131.84 85.59 123.7 99.69 131.84 99.69 111.48 94.79 108.65"
      />
      <polygon
        fill={fill}
        points="56.55 74.29 43.81 66.94 53.01 61.62 53.01 51 74.95 63.67 79.85 60.84 48.11 42.52 48.11 58.8 34.02 66.94 51.65 77.12 56.55 74.29"
      />
      <polygon
        fill={fill}
        points="184.38 62.61 184.38 58.43 169.19 58.43 169.19 47.74 185.5 47.74 185.5 43.54 164.27 43.54 164.27 77.56 185.7 77.56 185.7 73.36 169.19 73.36 169.19 62.61 184.38 62.61"
      />
      <path
        fill={fill}
        d="M212.34,64.56a16.57,16.57,0,0,0-.93-5.9,10.43,10.43,0,0,0-2.53-3.95,10,10,0,0,0-3.62-2.23,12.56,12.56,0,0,0-4.17-.71,11,11,0,0,0-10.29,6.32A15.61,15.61,0,0,0,189.33,65a15.43,15.43,0,0,0,1.47,6.91A10.81,10.81,0,0,0,195,76.46a12.47,12.47,0,0,0,6.47,1.61,13.56,13.56,0,0,0,5-.85A9.67,9.67,0,0,0,210,74.83a8.43,8.43,0,0,0,2-3.48l-4.5-.81a5.34,5.34,0,0,1-1.3,2,5.7,5.7,0,0,1-2.06,1.26,8.17,8.17,0,0,1-2.64.41,7.63,7.63,0,0,1-3.89-1,6.62,6.62,0,0,1-2.61-2.8,9.64,9.64,0,0,1-.9-4.09v-.13h18.26Zm-4.65-1.85h-13.6v-.13a7.7,7.7,0,0,1,.83-3.15,7.07,7.07,0,0,1,2.45-2.73,6.74,6.74,0,0,1,3.74-1.05,6.54,6.54,0,0,1,3.46.91A6.2,6.2,0,0,1,206.87,59a7.6,7.6,0,0,1,.82,3.56Z"
      />
      <path
        fill={fill}
        d="M219.09,76.25a12.23,12.23,0,0,0,7,1.82,13.52,13.52,0,0,0,5.43-1,8.7,8.7,0,0,0,3.65-2.77,6.7,6.7,0,0,0,1.3-4,5.84,5.84,0,0,0-1.7-4.34,10.88,10.88,0,0,0-5.23-2.5h0l-4.08-.93a7.83,7.83,0,0,1-3.13-1.32,2.7,2.7,0,0,1-1-2.17,3,3,0,0,1,1.48-2.54,6.41,6.41,0,0,1,3.61-1A6.08,6.08,0,0,1,229,56a4.64,4.64,0,0,1,1.69,1.32,5.9,5.9,0,0,1,.89,1.6l4.29-.76a8.41,8.41,0,0,0-3.08-4.62,10.69,10.69,0,0,0-6.49-1.77,12.86,12.86,0,0,0-5.08.95,8.35,8.35,0,0,0-3.47,2.63,6.44,6.44,0,0,0-1.24,3.91,6.19,6.19,0,0,0,1.68,4.45,10.3,10.3,0,0,0,5.25,2.57l4.35,1a6.2,6.2,0,0,1,2.79,1.26,2.78,2.78,0,0,1,.94,2.12A3.15,3.15,0,0,1,230,73.24a6.88,6.88,0,0,1-4,1.05,6.55,6.55,0,0,1-3.74-1,4.74,4.74,0,0,1-1.88-2.82l-4.59.7A7.65,7.65,0,0,0,219.09,76.25Z"
      />
      <path
        fill={fill}
        d="M243.29,75A6.27,6.27,0,0,0,246,77.2a8.8,8.8,0,0,0,3.63.69,9.68,9.68,0,0,0,2-.19,8.53,8.53,0,0,0,1.12-.3l-.85-3.91-.59.11a5.63,5.63,0,0,1-1.07.09,4.41,4.41,0,0,1-1.56-.27,2.3,2.3,0,0,1-1.19-1,4.48,4.48,0,0,1-.46-2.25V55.87h5.27V52.11H247V46h-4.75v6.16H238.5v3.76h3.76v15.3A6.65,6.65,0,0,0,243.29,75Z"
      />
      <path
        fill={fill}
        d="M259.5,42.17a3,3,0,0,0-2.15.84,2.71,2.71,0,0,0-.89,2,2.66,2.66,0,0,0,.89,2,3.13,3.13,0,0,0,4.29,0,2.62,2.62,0,0,0,.89-2,2.67,2.67,0,0,0-.89-2A3,3,0,0,0,259.5,42.17Z"
      />
      <rect fill={fill} x="257.09" y="52.11" width="4.75" height="25.45" />
      <polygon
        fill={fill}
        points="190.54 87.83 164.27 87.83 164.27 92.03 174.94 92.03 174.94 121.85 179.85 121.85 179.85 92.03 190.54 92.03 190.54 87.83"
      />
      <path
        fill={fill}
        d="M210.27,115.65l-4.5-.81a5.51,5.51,0,0,1-1.3,2,5.74,5.74,0,0,1-2.07,1.26,8.15,8.15,0,0,1-2.63.41,7.7,7.7,0,0,1-3.9-1,6.6,6.6,0,0,1-2.6-2.8,9.64,9.64,0,0,1-.91-4.09v-.13h18.26v-1.64a16.81,16.81,0,0,0-.93-5.91A10.64,10.64,0,0,0,207.16,99a10.13,10.13,0,0,0-3.62-2.23,12.37,12.37,0,0,0-4.18-.72,10.94,10.94,0,0,0-10.28,6.32,15.5,15.5,0,0,0-1.47,6.91,15.29,15.29,0,0,0,1.47,6.91,10.79,10.79,0,0,0,4.16,4.55,12.51,12.51,0,0,0,6.48,1.62,13.5,13.5,0,0,0,5-.86,9.79,9.79,0,0,0,3.62-2.39A8.46,8.46,0,0,0,210.27,115.65Zm-17.9-8.78a7.75,7.75,0,0,1,.83-3.15,7.17,7.17,0,0,1,2.46-2.73,6.71,6.71,0,0,1,3.74-1,6.5,6.5,0,0,1,3.45.9,6.29,6.29,0,0,1,2.3,2.47,7.59,7.59,0,0,1,.81,3.56V107h-13.6Z"
      />
      <path
        fill={fill}
        d="M218.19,121.52a10.43,10.43,0,0,0,4.36.9,9.64,9.64,0,0,0,3.6-.61,7.71,7.71,0,0,0,2.46-1.53A7.05,7.05,0,0,0,230,118.4l0-.07h.4v3.52h4.64V104.91a9.42,9.42,0,0,0-1-4.52,7.21,7.21,0,0,0-2.45-2.67,9.47,9.47,0,0,0-3.2-1.3,15.84,15.84,0,0,0-3.25-.36,14.44,14.44,0,0,0-4.39.66,9.88,9.88,0,0,0-3.68,2.08,9,9,0,0,0-2.37,3.61l4.46,1a6.31,6.31,0,0,1,2-2.42,6.5,6.5,0,0,1,4.05-1.14,5.17,5.17,0,0,1,3.74,1.24,4.7,4.7,0,0,1,1.27,3.49v.12a1.54,1.54,0,0,1-.71,1.41,5.72,5.72,0,0,1-2.14.59l-3.75.45a29.92,29.92,0,0,0-3.57.63A11.8,11.8,0,0,0,217,109a6.19,6.19,0,0,0-2.22,2.18,6.89,6.89,0,0,0-.82,3.52,7.48,7.48,0,0,0,1.13,4.2A7.32,7.32,0,0,0,218.19,121.52Zm1.2-9a4.61,4.61,0,0,1,2-1.29,12.25,12.25,0,0,1,2.68-.62l1.53-.21c.62-.08,1.25-.18,1.89-.28a14.79,14.79,0,0,0,1.72-.39,2.47,2.47,0,0,0,1-.46l.21-.21v3.61a5.68,5.68,0,0,1-.8,2.92,6,6,0,0,1-2.3,2.2,7.45,7.45,0,0,1-3.62.83,6.11,6.11,0,0,1-3.57-1,3.32,3.32,0,0,1-1.42-2.9A3.28,3.28,0,0,1,219.39,112.55Z"
      />
      <path
        fill={fill}
        d="M261.72,121.85v-34H257v12.73h-.5l0-.06a15.08,15.08,0,0,0-1.25-1.84,6.94,6.94,0,0,0-2.24-1.82,7.94,7.94,0,0,0-3.79-.78,9.85,9.85,0,0,0-5.47,1.56,10.52,10.52,0,0,0-3.8,4.48,16.54,16.54,0,0,0-1.38,7.07,16.79,16.79,0,0,0,1.37,7.09,10.48,10.48,0,0,0,3.77,4.51,9.74,9.74,0,0,0,5.47,1.58,8,8,0,0,0,3.76-.77,7.19,7.19,0,0,0,2.28-1.81,12.81,12.81,0,0,0,1.28-1.86l0-.06h.62v4Zm-5.45-7.93a7.23,7.23,0,0,1-2.32,3.24,6.56,6.56,0,0,1-7.55,0,7.47,7.47,0,0,1-2.32-3.31,14.31,14.31,0,0,1,0-9.29,7.18,7.18,0,0,1,2.31-3.25,6.72,6.72,0,0,1,7.59-.05,7.06,7.06,0,0,1,2.31,3.18,15,15,0,0,1,0,9.53Z"
      />
      <path
        fill={fill}
        d="M286.87,121.85V96.4H282.1v14.91a6.59,6.59,0,0,1-.93,3.69,6.17,6.17,0,0,1-2.38,2.17,6.36,6.36,0,0,1-2.86.71,5.1,5.1,0,0,1-3.87-1.59,6,6,0,0,1-1.49-4.27V96.4h-4.75v16.22a11.88,11.88,0,0,0,1.06,5.28,7.48,7.48,0,0,0,2.93,3.2,8.47,8.47,0,0,0,4.32,1.08,8.16,8.16,0,0,0,4.77-1.34,7.89,7.89,0,0,0,2.79-3.37l0-.07h.48v4.45Z"
      />
      <path
        fill={fill}
        d="M293.43,120.54a12.25,12.25,0,0,0,7,1.83,13.66,13.66,0,0,0,5.43-1,8.74,8.74,0,0,0,3.65-2.78,6.62,6.62,0,0,0,1.31-4,5.85,5.85,0,0,0-1.71-4.34,10.75,10.75,0,0,0-5.22-2.49l-4.09-.94a7.83,7.83,0,0,1-3.13-1.32,2.68,2.68,0,0,1-1-2.17,3,3,0,0,1,1.48-2.54,6.44,6.44,0,0,1,3.62-1,6.14,6.14,0,0,1,2.65.51,4.72,4.72,0,0,1,1.69,1.33,5.67,5.67,0,0,1,.88,1.59l4.29-.76a8.3,8.3,0,0,0-3.08-4.61,10.59,10.59,0,0,0-6.48-1.78,12.91,12.91,0,0,0-5.09,1,8.32,8.32,0,0,0-3.46,2.63,6.39,6.39,0,0,0-1.25,3.91,6.21,6.21,0,0,0,1.68,4.45,10.27,10.27,0,0,0,5.25,2.57l4.35,1a6.2,6.2,0,0,1,2.79,1.26,2.76,2.76,0,0,1,.94,2.12,3.12,3.12,0,0,1-1.53,2.62,6.84,6.84,0,0,1-4,1,6.5,6.5,0,0,1-3.73-1,4.62,4.62,0,0,1-1.88-2.82l-4.6.71A7.62,7.62,0,0,0,293.43,120.54Z"
      />
      <path
        fill={fill}
        d="M317.11,121.52a10.47,10.47,0,0,0,4.37.9,9.72,9.72,0,0,0,3.6-.61,7.77,7.77,0,0,0,2.45-1.53A7.05,7.05,0,0,0,329,118.4l0-.07h.4v3.52H334V104.91a9.42,9.42,0,0,0-.95-4.52,7.19,7.19,0,0,0-2.44-2.67,9.61,9.61,0,0,0-3.21-1.3,15.82,15.82,0,0,0-3.24-.36,14.56,14.56,0,0,0-4.4.66,9.88,9.88,0,0,0-3.68,2.08,9,9,0,0,0-2.37,3.61l4.46,1a6.4,6.4,0,0,1,2-2.42,6.52,6.52,0,0,1,4.05-1.14A5.18,5.18,0,0,1,328,101.1a4.7,4.7,0,0,1,1.26,3.49v.12a1.54,1.54,0,0,1-.71,1.41,5.72,5.72,0,0,1-2.14.59l-3.75.45a30.11,30.11,0,0,0-3.56.63A11.74,11.74,0,0,0,316,109a6.08,6.08,0,0,0-2.21,2.18,6.79,6.79,0,0,0-.82,3.52,7.56,7.56,0,0,0,1.12,4.2A7.32,7.32,0,0,0,317.11,121.52Zm1.2-9a4.74,4.74,0,0,1,2-1.29,12.25,12.25,0,0,1,2.68-.62l1.54-.21c.61-.08,1.25-.18,1.88-.28a14.33,14.33,0,0,0,1.72-.39,2.4,2.4,0,0,0,1-.46l.21-.21v3.61a5.76,5.76,0,0,1-.79,2.92,6.05,6.05,0,0,1-2.31,2.2,7.45,7.45,0,0,1-3.62.83,6.1,6.1,0,0,1-3.56-1,3.69,3.69,0,0,1-.69-5.13Z"
      />
      <path
        fill={fill}
        d="M344,131.23a15.58,15.58,0,0,0,5.22.79,16.09,16.09,0,0,0,5.85-1,8.39,8.39,0,0,0,5.62-8.47V96.4H356v4.16h-.57l0-.06a15.52,15.52,0,0,0-1.27-1.84,7.14,7.14,0,0,0-2.26-1.82,8,8,0,0,0-3.81-.78,9.93,9.93,0,0,0-5.47,1.55,10.53,10.53,0,0,0-3.8,4.44,16.25,16.25,0,0,0-1.38,7,15.17,15.17,0,0,0,1.4,6.85,9.92,9.92,0,0,0,3.8,4.18,10.53,10.53,0,0,0,5.42,1.41,8.5,8.5,0,0,0,3.77-.72,7.13,7.13,0,0,0,2.28-1.71,12.06,12.06,0,0,0,1.28-1.8l0-.07h.52v5.18a5.26,5.26,0,0,1-1.91,4.49,7.79,7.79,0,0,1-4.78,1.41,8.49,8.49,0,0,1-3.44-.6,6.28,6.28,0,0,1-2.11-1.44,10.92,10.92,0,0,1-1.18-1.49l-4.08,1.68a8.76,8.76,0,0,0,2,2.74A10.27,10.27,0,0,0,344,131.23Zm11.22-17.67a6.77,6.77,0,0,1-9.86,2.91,6.72,6.72,0,0,1-2.33-3,11.91,11.91,0,0,1-.78-4.48,12.61,12.61,0,0,1,.77-4.5,7.3,7.3,0,0,1,2.31-3.21,6.11,6.11,0,0,1,3.84-1.19,6.17,6.17,0,0,1,3.76,1.14,7,7,0,0,1,2.3,3.14A12.89,12.89,0,0,1,356,109,12.19,12.19,0,0,1,355.19,113.56Z"
      />
      <path
        fill={fill}
        d="M387.07,108.86a16.59,16.59,0,0,0-.93-5.91A10.39,10.39,0,0,0,383.61,99,10,10,0,0,0,380,96.78a12.32,12.32,0,0,0-4.17-.72,11,11,0,0,0-10.29,6.32,15.61,15.61,0,0,0-1.47,6.91,15.43,15.43,0,0,0,1.47,6.91,10.81,10.81,0,0,0,4.17,4.55,12.48,12.48,0,0,0,6.47,1.62,13.56,13.56,0,0,0,5-.86,9.79,9.79,0,0,0,3.62-2.39,8.55,8.55,0,0,0,2-3.47l-4.5-.81a5.49,5.49,0,0,1-1.29,2,5.82,5.82,0,0,1-2.07,1.26,8.17,8.17,0,0,1-2.64.41,7.66,7.66,0,0,1-3.89-1,6.54,6.54,0,0,1-2.6-2.8,9.49,9.49,0,0,1-.91-4.09v-.13h18.26ZM382.42,107h-13.6v-.14a7.75,7.75,0,0,1,.83-3.15,7.23,7.23,0,0,1,2.45-2.73,6.74,6.74,0,0,1,3.74-1,6.54,6.54,0,0,1,3.46.9,6.2,6.2,0,0,1,2.29,2.47,7.6,7.6,0,0,1,.82,3.56Z"
      />
      <path
        fill={fill}
        d="M398.21,100.91a6.47,6.47,0,0,1,3.15-.76,5.3,5.3,0,0,1,4.09,1.62,6.34,6.34,0,0,1,1.49,4.46v15.62h4.75V105.63a11.81,11.81,0,0,0-1.07-5.29,7.41,7.41,0,0,0-3-3.2,9.35,9.35,0,0,0-4.54-1.08,8.52,8.52,0,0,0-4.73,1.22,7.25,7.25,0,0,0-2.74,3.22l0,.08H395V96.4h-4.55v25.45h4.75V106.71a7.42,7.42,0,0,1,.8-3.55A5.6,5.6,0,0,1,398.21,100.91Z"
      />
      <path
        fill={fill}
        d="M419,119.26a6.18,6.18,0,0,0,2.7,2.23,8.74,8.74,0,0,0,3.47.69h.16a9.71,9.71,0,0,0,2-.19,9,9,0,0,0,1.12-.3l-.86-3.9-.58.11a6.72,6.72,0,0,1-1.08.08,4.41,4.41,0,0,1-1.56-.27,2.36,2.36,0,0,1-1.19-1,4.51,4.51,0,0,1-.45-2.25V100.16H428V96.4h-5.27V90.24H418V96.4h-3.77v3.76H418v15.3A6.57,6.57,0,0,0,419,119.26Z"
      />
      <path
        fill={fill}
        d="M452.4,121.85V96.4h-4.77v14.91a6.59,6.59,0,0,1-.93,3.69,6.17,6.17,0,0,1-2.38,2.17,6.39,6.39,0,0,1-2.86.71,5.1,5.1,0,0,1-3.87-1.59A6,6,0,0,1,436.1,112V96.4h-4.75v16.22a11.88,11.88,0,0,0,1.06,5.28,7.48,7.48,0,0,0,2.93,3.2,8.47,8.47,0,0,0,4.32,1.08,8.16,8.16,0,0,0,4.77-1.34,7.89,7.89,0,0,0,2.79-3.37l0-.07h.48v4.45Z"
      />
      <path
        fill={fill}
        d="M478,121.85V96.4h-4.78v14.91a6.51,6.51,0,0,1-.93,3.69,6.14,6.14,0,0,1-2.37,2.17,6.43,6.43,0,0,1-2.86.71,5.1,5.1,0,0,1-3.87-1.59,6,6,0,0,1-1.49-4.27V96.4H457v16.22A11.74,11.74,0,0,0,458,117.9a7.42,7.42,0,0,0,2.93,3.2,8.46,8.46,0,0,0,4.31,1.08,8.14,8.14,0,0,0,4.77-1.34,7.84,7.84,0,0,0,2.8-3.37l0-.07h.48v4.45Z"
      />
      <path
        fill={fill}
        d="M490.49,101.25a6.65,6.65,0,0,1,3.21-.76,11.69,11.69,0,0,1,1.56.11c.41.06.72.11.93.16V96.24c-.23,0-.53,0-.89-.07l-1.11,0a7,7,0,0,0-4,1.17,6.18,6.18,0,0,0-2.44,3.21l0,.09h-.48V96.52h-4.58V122h4.75V106.4a5.7,5.7,0,0,1,3.05-5.15Z"
      />
    </svg>
  );
};

export default EtagLogo;

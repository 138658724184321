import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const PlanetIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 20,
  fill = '#69728C',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-733.000000, -554.000000)" fill={fill}>
          <g transform="translate(131.000000, 173.000000)">
            <g transform="translate(276.000000, 379.000000)">
              <g transform="translate(326.000000, 0.000000)">
                <g transform="translate(0.000000, 2.000000)">
                  <path d="M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M12.6133333,7.33333333 L10.9,7.33333333 C10.8628613,6.13153641 10.5958261,4.94798219 10.1133333,3.84666667 C11.4631857,4.53422974 12.3953085,5.83423043 12.6133333,7.33333333 Z M8.3,12.6666667 L8,12.6666667 L7.7,12.6666667 C7.09333333,12.2866667 6.36666667,10.8266667 6.27333333,8.66666667 L9.72666667,8.66666667 C9.60666667,10.8133333 8.90666667,12.2733333 8.3,12.6666667 L8.3,12.6666667 Z M6.3,7.33333333 C6.42,5.18666667 7.12,3.72666667 7.72666667,3.33333333 L8,3.33333333 L8.3,3.33333333 C8.90666667,3.71333333 9.63333333,5.17333333 9.72666667,7.33333333 L6.3,7.33333333 Z M5.88666667,3.84666667 C5.40417393,4.94798219 5.13713875,6.13153641 5.1,7.33333333 L3.38666667,7.33333333 C3.60469145,5.83423043 4.53681432,4.53422974 5.88666667,3.84666667 Z M3.38666667,8.66666667 L5.1,8.66666667 C5.13713875,9.86846359 5.40417393,11.0520178 5.88666667,12.1533333 C4.53681432,11.4657703 3.60469145,10.1657696 3.38666667,8.66666667 L3.38666667,8.66666667 Z M10.1133333,12.1533333 C10.5958261,11.0520178 10.8628613,9.86846359 10.9,8.66666667 L12.6133333,8.66666667 C12.3953085,10.1657696 11.4631857,11.4657703 10.1133333,12.1533333 L10.1133333,12.1533333 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PlanetIcon;

// This must match sub-domain name of the application:
export const APP_NAME = 'ETIS';
export const IS_MOBILE_VIEW = 992;

export const IS_LOCALHOST = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export const IS_PRODUCTION = window.location.host === 'etis.ee';
export const IS_DEMO = window.location.host === 'etis.ee';

import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const ClearIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 32,
  height = 32,
  fill = '#08090D',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1059.000000, -56.000000)" fill={fill}>
          <g transform="translate(227.000000, 24.000000)">
            <g transform="translate(832.000000, 32.000000)">
              <path d="M23.8,21.92 C23.9262084,22.0451777 23.9971989,22.215575 23.9971989,22.3933333 C23.9971989,22.5710917 23.9262084,22.7414889 23.8,22.8666667 L22.8666667,23.8 C22.7414889,23.9262084 22.5710917,23.9971989 22.3933333,23.9971989 C22.215575,23.9971989 22.0451777,23.9262084 21.92,23.8 L16,17.88 L10.08,23.8 C9.95482227,23.9262084 9.78442502,23.9971989 9.60666667,23.9971989 C9.42890831,23.9971989 9.25851107,23.9262084 9.13333333,23.8 L8.2,22.8666667 C8.07379157,22.7414889 8.00280112,22.5710917 8.00280112,22.3933333 C8.00280112,22.215575 8.07379157,22.0451777 8.2,21.92 L14.12,16 L8.2,10.08 C8.07379157,9.95482227 8.00280112,9.78442502 8.00280112,9.60666667 C8.00280112,9.42890831 8.07379157,9.25851107 8.2,9.13333333 L9.13333333,8.2 C9.25851107,8.07379157 9.42890831,8.00280112 9.60666667,8.00280112 C9.78442502,8.00280112 9.95482227,8.07379157 10.08,8.2 L16,14.12 L21.92,8.2 C22.0451777,8.07379157 22.215575,8.00280112 22.3933333,8.00280112 C22.5710917,8.00280112 22.7414889,8.07379157 22.8666667,8.2 L23.8,9.13333333 C23.9262084,9.25851107 23.9971989,9.42890831 23.9971989,9.60666667 C23.9971989,9.78442502 23.9262084,9.95482227 23.8,10.08 L17.88,16 L23.8,21.92 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ClearIcon;

import React, { Suspense, FC, ReactElement } from 'react';
import 'assets/animate.css';
import '../App.scss';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from 'theme';
import { FullPageLoader } from 'components';
import AppContents from './AppContents';

const App: FC = (): ReactElement => {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<FullPageLoader />}>
        <AppContents />
      </Suspense>
    </ThemeProvider>
  );
};

export default App;

import React, { FC, ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  panel: {
    height: '48px',
    position: 'relative',
    zIndex: 200,
  },
  text: {
    lineHeight: '48px',
    textAlign: 'center',
  },
}));

const EnvironmentPanel: FC = (): ReactElement => {
  const classes = useStyles();

  return (
    <Box className={classes.panel}>
      <Typography variant="body2" className={classes.text}>
        THIS IS DEMO
      </Typography>
    </Box>
  );
};

export default EnvironmentPanel;

import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const MenuHamburgerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 24,
  height = 24,
  fill = '#2343E6',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-128.000000, -3134.000000)" fill={fill}>
          <g transform="translate(112.000000, 3110.000000)">
            <g transform="translate(16.000000, 24.000000)">
              <path d="M20.5,7 L3.5,7 C3.22385763,7 3,6.77614237 3,6.5 L3,5.5 C3,5.22385763 3.22385763,5 3.5,5 L20.5,5 C20.7761424,5 21,5.22385763 21,5.5 L21,6.5 C21,6.77614237 20.7761424,7 20.5,7 Z M21,12.5 L21,11.5 C21,11.2238576 20.7761424,11 20.5,11 L3.5,11 C3.22385763,11 3,11.2238576 3,11.5 L3,12.5 C3,12.7761424 3.22385763,13 3.5,13 L20.5,13 C20.7761424,13 21,12.7761424 21,12.5 Z M21,18.5 L21,17.5 C21,17.2238576 20.7761424,17 20.5,17 L3.5,17 C3.22385763,17 3,17.2238576 3,17.5 L3,18.5 C3,18.7761424 3.22385763,19 3.5,19 L20.5,19 C20.7761424,19 21,18.7761424 21,18.5 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MenuHamburgerIcon;

import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const EtisLogoPositive: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 88,
  height = 32,
  fill = '#2443e4',
}): ReactElement => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 695">
      <path
        fill={fill}
        d="M693.45,412.93H918.79q0-105.55-44-150.5T754,217.5q-62.79,0-113.82,24.87T559.46,320q-29.67,52.78-29.65,136.53,0,88.12,27,140.46t72.83,75q45.78,22.66,101.61,22.68,52.32,0,89-22.25A189.17,189.17,0,0,0,880,615.78q23.09-34.47,33.58-69.36l-14.84-5.24Q870,581.32,840.29,590.47t-47.1,9.16q-23.54,0-46.66-13.08t-38.37-47.12q-15.25-34-15.26-95.1V426C692.9,421.66,693.12,417.3,693.45,412.93Zm54.39-175.36q16.56,0,26.17,21.81t14,58a568.85,568.85,0,0,1,3.49,79H690.56q4.53-85.67,18.91-121.7Q724.29,237.57,747.84,237.57Z"
      />
      <path
        fill={fill}
        d="M1210.08,635q-13.08,5.67-22.68,5.66-14,0-20.06-7.41t-6.1-24V247.16h85.47V229.72h-85.47V88.38a274.46,274.46,0,0,1-72.83,29.23q-37.94,9.17-84.16,9.16v103H938v17.44h66.29V543.8q0,45.38,5.23,73.72t24.42,47.54q14.82,14.85,37.94,22.25t51.89,7.42q45.35,0,75.44-18.76a142.84,142.84,0,0,0,48.41-50.17l-13.08-10.46Q1223.16,629.29,1210.08,635Z"
      />
      <path
        fill={fill}
        d="M1316.91,131.57q24.87,18.77,70.21,18.75t70.21-18.75q24.86-18.75,24.86-56.27T1457.33,19Q1432.48.27,1387.12.27T1316.91,19q-24.86,18.78-24.86,56.28T1316.91,131.57Z"
      />
      <path
        fill={fill}
        d="M1481.75,651.1q-10.92-13.08-10.9-49.72V220.12a662.59,662.59,0,0,1-70.21,9.16q-38,3.08-81.55,3.05-26.16,0-54.07-2.61V248q27.9,0,38.38,18.76t10.46,58V601.38q0,36.63-10.9,49.72T1265,664.19v18.32q19.17-.85,56.69-2.18t74.13-1.31q36.63,0,71.52,1.31t52.33,2.18V664.19Q1492.65,664.19,1481.75,651.1Z"
      />
      <path
        fill={fill}
        d="M1893,477.49q-27.06-32.26-67.16-64.56Q1801.39,392,1777,373.23T1736.85,334a74.74,74.74,0,0,1-15.7-46.68q0-22.68,14.83-35.77t36.63-13.08q35.75,0,58,20.93t34,52.35a260.82,260.82,0,0,1,15.26,63.69h17.45q0-59.32.87-95.53t2.61-61.52h-17.44q-7,41-37.5,22.69-13.08-7-42.3-15.27T1736,217.5q-42.75,0-80.24,15.27T1595.12,276Q1572,303.9,1572,343.13q0,34,14.83,61.52a217.12,217.12,0,0,0,37.93,50.59q23.1,23.13,49.28,47.56,34.88,31.4,58,57.58t23.1,64.55q0,24.44-15.26,36.21a56.23,56.23,0,0,1-35.32,11.78,82.68,82.68,0,0,1-55.39-20.51q-24-20.49-39.24-56.27t-20.5-82H1572q0,66.32-.87,106.44t-2.62,68H1586a75.32,75.32,0,0,1,9.59-21.38q6.09-9.15,14.83-9.15A53.82,53.82,0,0,1,1630,662q10,3.93,24,10.91,14,8.73,36.64,15.27t53.2,6.54q48.83,0,88.52-17T1896,630.17q24-30.54,24-69.79Q1920,509.79,1893,477.49Z"
      />
      <rect
        fill={fill}
        x="234.58"
        y="23.29"
        width="52.73"
        height="52.73"
        transform="translate(41.3 199.02) rotate(-44.99)"
      />
      <rect
        fill={fill}
        x="160.02"
        y="23.29"
        width="52.73"
        height="52.73"
        transform="translate(19.47 146.31) rotate(-44.99)"
      />
      <rect
        fill={fill}
        x="383.68"
        y="172.44"
        width="52.73"
        height="52.73"
        transform="translate(-20.5 348.11) rotate(-44.99)"
      />
      <rect
        fill={fill}
        x="383.68"
        y="247.02"
        width="52.73"
        height="52.73"
        transform="translate(-73.2 370.1) rotate(-45.01)"
      />
      <rect
        fill={fill}
        x="10.91"
        y="247.02"
        width="52.73"
        height="52.73"
        transform="translate(-182.37 106.4) rotate(-44.99)"
      />
      <rect
        fill={fill}
        x="10.91"
        y="172.44"
        width="52.73"
        height="52.73"
        transform="translate(-129.68 84.61) rotate(-45.01)"
      />
      <rect
        fill={fill}
        x="160.02"
        y="396.18"
        width="52.73"
        height="52.73"
        transform="translate(-244.16 255.48) rotate(-44.99)"
      />
      <rect
        fill={fill}
        x="234.58"
        y="396.18"
        width="52.73"
        height="52.73"
        transform="translate(-222.33 308.19) rotate(-44.99)"
      />
      <path
        fill={fill}
        d="M372.77,348l-37.28-37.29-.16.17V161.35l.16.17,37.28-37.29,37.28-37.29L372.77,49.65,335.49,86.94l-37.27,37.29,0,0H149.09l0,0L111.83,86.94,74.55,49.65,37.28,86.94l37.27,37.29,37.28,37.29.09-.09V310.76l-.09-.09L74.55,348,37.28,385.25l37.27,37.29,37.28-37.29L149.11,348l-.23-.23H298.45l-.23.23,37.27,37.29,37.28,37.29,37.28-37.29Zm-216-45.12V169.14H290.45v133.7Z"
      />
      <polygon
        fill={fill}
        points="249.98 209.62 197.26 209.62 197.26 262.36 197.3 262.36 197.3 262.46 250.02 262.46 250.02 209.73 249.98 209.73 249.98 209.62"
      />{' '}
    </svg>
  );
};

export default EtisLogoPositive;

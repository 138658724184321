import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const PhoneIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 24,
  height = 24,
  fill = '#000000',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="OVERVIEW" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Icons"
          transform="translate(-444.000000, -1300.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="ico-/-24-/-communication-/-call" transform="translate(444.000000, 1300.000000)">
            <path
              d="M16.9999994,21.0000006 C15.3462556,20.9985641 13.7183167,20.5898621 12.26,19.81 L11.81,19.56 C8.70075057,17.8883077 6.15169228,15.3392494 4.48,12.23 L4.23,11.78 C3.4298235,10.3133715 3.00712709,8.67069766 2.99999937,7 L2.99999937,6.33 C2.99957762,5.79695542 3.2119606,5.28579638 3.59,4.91 L5.28,3.22 C5.44412558,3.05459773 5.67580565,2.97487483 5.90695864,3.00425868 C6.13811163,3.03364254 6.34247705,3.16879495 6.46,3.37 L8.71,7.23 C8.9375312,7.62288856 8.87182995,8.11975423 8.55,8.44 L6.66,10.33 C6.50304377,10.4852343 6.46646482,10.7250297 6.57,10.92 L6.92,11.58 C8.17704189,13.908453 10.0892632,15.8171976 12.42,17.07 L13.08,17.43 C13.2749703,17.5335352 13.5147657,17.4969562 13.67,17.34 L15.56,15.45 C15.8802458,15.12817 16.3771114,15.0624688 16.77,15.29 L20.63,17.54 C20.831205,17.657523 20.9663575,17.8618884 20.9957413,18.0930414 C21.0251252,18.3241943 20.9454023,18.5558744 20.78,18.72 L19.09,20.41 C18.7142036,20.7880394 18.2030446,21.0004224 17.67,21.0000006 L16.9999994,21.0000006 Z"
              id="Icon-color"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PhoneIcon;

import React, { FC, ReactElement } from 'react';
import clsx from 'clsx';
import { NavLinkProps } from 'portal-menu';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { generateKey } from 'shared/functions/generateKey';

type NavItemProps = {
  item: NavLinkProps;
  isActive?: boolean;
};

const NavItem: FC<NavItemProps> = ({ item, isActive }): ReactElement => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const { t } = useTranslation();
  const hasSubItems = item.children && item.children.length > 0;

  const location = useLocation();

  const isNavActive = (itemPath: string): boolean => {
    const currentPath = location.pathname;
    const itemPathParts: string[] = itemPath.split('/');
    const itemUrlStart = '/';
    const itemUrlPathItems: string[] = [];

    for (let i = 0; i < itemPathParts.length; i += 1) {
      if (i > 2) {
        break;
      }
      if (i > 0) {
        itemUrlPathItems.push(itemPathParts[i]);
      }
    }

    const pathStart = itemUrlStart + itemUrlPathItems.join('/');
    return currentPath.startsWith(pathStart) && currentPath !== '/';
  };

  const navLinkHtml = (link: NavLinkProps, itemIsActive: boolean, hasChildren?: boolean) => (
    <a
      href={link.path}
      tabIndex={0}
      role="menuitem"
      className={clsx(
        'nav-link link-dark',
        itemIsActive && 'is-active',
        hasChildren && 'dropdown-toggle',
      )}
    >
      {t(link.title)}
    </a>
  );

  return (
    <>
      <li className={clsx('nav-item', hasSubItems && 'dropdown')}>
        {!hasSubItems && navLinkHtml(item, isNavActive(item.path), hasSubItems)}
        {hasSubItems && (
          <Dropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
            direction="down"
          >
            <DropdownToggle
              caret
              nav
              className="nav-link link-dark dropdown-toggle"
              aria-haspopup
              aria-label={t(item.title)}
            >
              {t(item.title)}
            </DropdownToggle>
            <DropdownMenu right tabIndex={0}>
              <ul className="p-0">
                {item.children?.map((subItem, index) => {
                  return (
                    <li key={generateKey(index, 'subitem')} className="m-l-0">
                      {navLinkHtml(
                        subItem,
                        isNavActive(subItem.path),
                        subItem.children && subItem.children.length > 0,
                      )}
                    </li>
                  );
                })}
              </ul>
            </DropdownMenu>
          </Dropdown>
        )}
      </li>
    </>
  );
};

export default NavItem;

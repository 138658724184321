import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const EmailIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 24,
  height = 24,
  fill = '#000000',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="OVERVIEW" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Icons"
          transform="translate(-548.000000, -1300.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="ico-/-24-/-communication-/-email" transform="translate(548.000000, 1300.000000)">
            <path
              d="M20,4 L4,4 C2.8954305,4 2,4.8954305 2,6 L2,18 C2,19.1045695 2.8954305,20 4,20 L20,20 C21.1045695,20 22,19.1045695 22,18 L22,6 C22,4.8954305 21.1045695,4 20,4 Z M20,11 L13.65,15.45 C12.6590479,16.1426837 11.3409521,16.1426837 10.35,15.45 L4,11 L4,8.9 L11.35,14.05 C11.7408743,14.3212851 12.2591257,14.3212851 12.65,14.05 L20,8.9 L20,11 Z"
              id="Icon-color"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmailIcon;

import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const EtagLogoEng: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 300,
  height = 90,
  fill = '#6638b6',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 530.21 165.86"
    >
      <polygon
        fill={fill}
        points="123.06 43.08 105.42 53.26 105.42 58.92 118.16 51.56 118.16 62.18 127.36 67.5 105.42 80.17 105.42 85.82 137.16 67.5 123.06 59.36 123.06 43.08"
      />
      <circle
        fill={fill}
        cx="67.12"
        cy="39.38"
        r="4.98"
        transform="translate(-7.25 16.47) rotate(-13.28)"
      />
      <polygon
        fill={fill}
        points="94.78 50.48 94.78 59.4 94.78 65.06 94.78 90.28 99.69 93.11 99.69 91.94 99.68 91.94 99.68 83.48 99.68 62.23 99.68 56.58 99.68 50.48 99.68 36.92 78.37 36.92 78.37 41.83 94.78 41.83 94.78 50.48"
      />
      <path fill={fill} d="M56.3,113.47a5,5,0,1,0,6.8,1.82A5,5,0,0,0,56.3,113.47Z" />
      <polygon
        fill={fill}
        points="89.04 62.71 88.03 63.3 88.03 63.3 80.71 67.53 62.31 78.16 57.41 80.99 52.13 84.04 40.39 90.81 51.04 109.26 55.29 106.81 47.08 92.61 54.58 88.28 62.3 83.82 67.21 80.99 89.04 68.38 89.04 62.71"
      />
      <path fill={fill} d="M133.33,81.47a5,5,0,1,0,1.82,6.81A5,5,0,0,0,133.33,81.47Z" />
      <polygon
        fill={fill}
        points="114.89 108.52 107.39 104.19 99.66 99.73 94.76 96.9 72.93 84.29 68.02 87.12 69.03 87.71 69.03 87.7 76.36 91.93 94.76 102.56 99.66 105.38 104.94 108.43 116.68 115.21 127.33 96.76 123.09 94.31 114.89 108.52"
      />
      <polygon
        fill={fill}
        points="94.79 123.92 85.59 118.61 76.39 123.92 76.39 98.59 71.49 95.76 71.49 132.4 85.59 124.26 99.69 132.4 99.69 112.04 94.79 109.21 94.79 123.92"
      />
      <polygon
        fill={fill}
        points="56.55 74.85 43.81 67.5 53.01 62.18 53.01 51.56 74.95 64.22 79.85 61.4 48.11 43.08 48.11 59.36 34.02 67.5 51.65 77.68 56.55 74.85"
      />
      <polygon
        fill={fill}
        points="184.38 63.17 184.38 58.99 169.19 58.99 169.19 48.3 185.5 48.3 185.5 44.1 164.27 44.1 164.27 78.11 185.7 78.11 185.7 73.92 169.19 73.92 169.19 63.17 184.38 63.17"
      />
      <path
        fill={fill}
        d="M192,76.81a12.25,12.25,0,0,0,7,1.82,13.75,13.75,0,0,0,5.44-1,8.61,8.61,0,0,0,3.64-2.78,6.59,6.59,0,0,0,1.31-4,5.83,5.83,0,0,0-1.71-4.34,10.8,10.8,0,0,0-5.22-2.5L198.37,63a7.83,7.83,0,0,1-3.13-1.32,2.7,2.7,0,0,1-1-2.17A3,3,0,0,1,195.72,57a6.45,6.45,0,0,1,3.62-1,6.13,6.13,0,0,1,2.65.52,4.81,4.81,0,0,1,1.69,1.32,5.87,5.87,0,0,1,.88,1.6l4.3-.77a8.37,8.37,0,0,0-3.09-4.61,10.65,10.65,0,0,0-6.48-1.77,12.87,12.87,0,0,0-5.09,1,8.32,8.32,0,0,0-3.46,2.63,6.39,6.39,0,0,0-1.25,3.91,6.23,6.23,0,0,0,1.68,4.45,10.35,10.35,0,0,0,5.25,2.57l4.35,1a6.3,6.3,0,0,1,2.8,1.27,2.77,2.77,0,0,1,.93,2.12A3.13,3.13,0,0,1,203,73.8a6.84,6.84,0,0,1-4,1,6.52,6.52,0,0,1-3.73-1,4.71,4.71,0,0,1-1.89-2.82l-4.59.7A7.65,7.65,0,0,0,192,76.81Z"
      />
      <path
        fill={fill}
        d="M215.48,75.53a6.18,6.18,0,0,0,2.7,2.23,8.66,8.66,0,0,0,3.64.69,9.76,9.76,0,0,0,2-.19,9,9,0,0,0,1.12-.3l-.86-3.91-.58.11a5.74,5.74,0,0,1-1.08.09,4.41,4.41,0,0,1-1.56-.27,2.33,2.33,0,0,1-1.19-1,4.49,4.49,0,0,1-.45-2.25V56.43h5.27V52.66H219.2V46.51h-4.75v6.15h-3.77v3.77h3.77v15.3A6.57,6.57,0,0,0,215.48,75.53Z"
      />
      <path
        fill={fill}
        d="M244.51,54A12.69,12.69,0,0,0,232,54a11,11,0,0,0-4.14,4.6,15.46,15.46,0,0,0-1.47,6.94,15.31,15.31,0,0,0,1.47,6.9A11.11,11.11,0,0,0,232,77a12.76,12.76,0,0,0,12.48,0,11.11,11.11,0,0,0,4.14-4.59,15.31,15.31,0,0,0,1.48-6.9,15.46,15.46,0,0,0-1.48-6.94A11,11,0,0,0,244.51,54Zm.06,16.1a7.45,7.45,0,0,1-2.32,3.35,6.9,6.9,0,0,1-7.95,0A7.47,7.47,0,0,1,232,70.07a14.33,14.33,0,0,1,0-9.15,7.53,7.53,0,0,1,2.33-3.37,6.77,6.77,0,0,1,7.95,0,7.51,7.51,0,0,1,2.32,3.37,14.33,14.33,0,0,1,0,9.15Z"
      />
      <path
        fill={fill}
        d="M261.27,57.18a6.35,6.35,0,0,1,3.14-.77A5.32,5.32,0,0,1,268.5,58,6.38,6.38,0,0,1,270,62.5V78.12h4.76V61.89a11.6,11.6,0,0,0-1.08-5.28,7.35,7.35,0,0,0-3-3.2,9.21,9.21,0,0,0-4.54-1.08,8.46,8.46,0,0,0-4.72,1.22,7.19,7.19,0,0,0-2.74,3.22l0,.08h-.52V52.66h-4.55V78.12h4.75V63a7.53,7.53,0,0,1,.79-3.55A5.5,5.5,0,0,1,261.27,57.18Z"
      />
      <path
        fill={fill}
        d="M281.69,42.73a3,3,0,0,0-2.15.84,2.71,2.71,0,0,0-.89,2,2.66,2.66,0,0,0,.89,2,3,3,0,0,0,2.15.85,3.06,3.06,0,0,0,2.15-.85,2.66,2.66,0,0,0,.89-2,2.71,2.71,0,0,0-.89-2A3,3,0,0,0,281.69,42.73Z"
      />
      <rect fill={fill} x="279.29" y="52.66" width="4.75" height="25.45" />
      <path
        fill={fill}
        d="M291.6,77.78a10.32,10.32,0,0,0,4.37.9,9.52,9.52,0,0,0,3.6-.61A7.59,7.59,0,0,0,302,76.54a7.18,7.18,0,0,0,1.43-1.87l0-.07h.4v3.52h4.64v-17a9.4,9.4,0,0,0-1-4.51A7.22,7.22,0,0,0,305.13,54a9.63,9.63,0,0,0-3.21-1.29,15.82,15.82,0,0,0-3.24-.36,14.87,14.87,0,0,0-4.4.65,10,10,0,0,0-3.68,2.09,8.84,8.84,0,0,0-2.36,3.6l4.46,1a6.21,6.21,0,0,1,2-2.42,6.52,6.52,0,0,1,4.05-1.14,5.18,5.18,0,0,1,3.75,1.24,4.68,4.68,0,0,1,1.26,3.49V61a1.53,1.53,0,0,1-.71,1.41,5.52,5.52,0,0,1-2.14.6l-3.75.44a32.32,32.32,0,0,0-3.56.64,11.41,11.41,0,0,0-3.15,1.22,6,6,0,0,0-2.21,2.18,6.79,6.79,0,0,0-.82,3.52,7.58,7.58,0,0,0,1.12,4.2A7.23,7.23,0,0,0,291.6,77.78Zm1.2-9a4.82,4.82,0,0,1,2-1.28,12.25,12.25,0,0,1,2.68-.62L299,66.7c.62-.09,1.25-.18,1.88-.29a12.71,12.71,0,0,0,1.72-.38,2.4,2.4,0,0,0,1-.46l.21-.21V69a5.82,5.82,0,0,1-.79,2.92,6,6,0,0,1-2.31,2.19,7.34,7.34,0,0,1-3.62.84,6.18,6.18,0,0,1-3.56-1,3.7,3.7,0,0,1-.69-5.14Z"
      />
      <path
        fill={fill}
        d="M320.75,57.18a6.36,6.36,0,0,1,3.15-.77A5.33,5.33,0,0,1,328,58a6.38,6.38,0,0,1,1.49,4.47V78.12h4.75V61.89a11.74,11.74,0,0,0-1.07-5.28,7.35,7.35,0,0,0-3-3.2,9.21,9.21,0,0,0-4.54-1.08,8.52,8.52,0,0,0-4.73,1.22,7.25,7.25,0,0,0-2.74,3.22l0,.08h-.53V52.66H313V78.12h4.75V63a7.42,7.42,0,0,1,.8-3.55A5.54,5.54,0,0,1,320.75,57.18Z"
      />
      <path
        fill={fill}
        d="M176.5,109.43h.24l0,.07,6.93,12.92h5.61l-7.57-13.81.18-.07a9.37,9.37,0,0,0,1-.4,8.86,8.86,0,0,0,3.9-3.65A11.25,11.25,0,0,0,188.1,99a11.54,11.54,0,0,0-1.29-5.52,9.08,9.08,0,0,0-3.89-3.74,14.21,14.21,0,0,0-6.56-1.36H164.27v34h4.92v-13h7.31Zm-7.31-4.19V92.57h6.62a9.67,9.67,0,0,1,4.19.78,5.07,5.07,0,0,1,2.38,2.23,7.29,7.29,0,0,1,.76,3.43,7,7,0,0,1-.75,3.35,4.9,4.9,0,0,1-2.37,2.15,10.22,10.22,0,0,1-4.15.73Z"
      />
      <path
        fill={fill}
        d="M213.53,109.41a16.57,16.57,0,0,0-.93-5.9,10.68,10.68,0,0,0-2.53-4,10.27,10.27,0,0,0-3.62-2.23,12.61,12.61,0,0,0-4.18-.71A10.94,10.94,0,0,0,192,102.94a15.47,15.47,0,0,0-1.47,6.91,15.29,15.29,0,0,0,1.47,6.91,10.75,10.75,0,0,0,4.17,4.55,12.45,12.45,0,0,0,6.47,1.62,13.46,13.46,0,0,0,5-.86,9.79,9.79,0,0,0,3.62-2.39,8.43,8.43,0,0,0,2-3.48l-4.5-.8a5.51,5.51,0,0,1-1.3,2,5.74,5.74,0,0,1-2.07,1.26,8.15,8.15,0,0,1-2.63.41,7.7,7.7,0,0,1-3.9-1,6.6,6.6,0,0,1-2.6-2.8,9.64,9.64,0,0,1-.91-4.09v-.13h18.26Zm-4.65-1.85H195.27v-.13a7.75,7.75,0,0,1,.83-3.15,7.18,7.18,0,0,1,2.46-2.73,6.74,6.74,0,0,1,3.74-1,6.5,6.5,0,0,1,3.45.91,6.29,6.29,0,0,1,2.3,2.47,7.6,7.6,0,0,1,.82,3.56Z"
      />
      <path
        fill={fill}
        d="M219.24,121.1a12.29,12.29,0,0,0,7,1.83,13.56,13.56,0,0,0,5.43-1,8.67,8.67,0,0,0,3.64-2.77,6.64,6.64,0,0,0,1.31-4,5.84,5.84,0,0,0-1.7-4.34,10.88,10.88,0,0,0-5.23-2.5l-4.08-.93a7.7,7.7,0,0,1-3.13-1.32,2.66,2.66,0,0,1-1-2.17,3,3,0,0,1,1.47-2.54,6.47,6.47,0,0,1,3.62-1,6.14,6.14,0,0,1,2.65.51,4.81,4.81,0,0,1,1.69,1.32,5.61,5.61,0,0,1,.88,1.6l4.3-.76A8.43,8.43,0,0,0,233,98.39a10.63,10.63,0,0,0-6.48-1.77,12.94,12.94,0,0,0-5.09.95A8.38,8.38,0,0,0,218,100.2a6.39,6.39,0,0,0-1.25,3.91,6.23,6.23,0,0,0,1.68,4.45,10.35,10.35,0,0,0,5.25,2.57l4.35,1a6.19,6.19,0,0,1,2.8,1.26,2.78,2.78,0,0,1,.93,2.12,3.14,3.14,0,0,1-1.52,2.62,6.9,6.9,0,0,1-4,1,6.52,6.52,0,0,1-3.73-1,4.69,4.69,0,0,1-1.88-2.82l-4.6.7A7.65,7.65,0,0,0,219.24,121.1Z"
      />
      <path
        fill={fill}
        d="M261.93,109.41a16.57,16.57,0,0,0-.93-5.9,10.68,10.68,0,0,0-2.53-4,10.33,10.33,0,0,0-3.62-2.23,12.6,12.6,0,0,0-4.17-.71,10.94,10.94,0,0,0-10.29,6.32,15.47,15.47,0,0,0-1.47,6.91,15.29,15.29,0,0,0,1.47,6.91,10.75,10.75,0,0,0,4.17,4.55,12.45,12.45,0,0,0,6.47,1.62,13.46,13.46,0,0,0,5-.86,9.71,9.71,0,0,0,3.62-2.39,8.43,8.43,0,0,0,2-3.48l-4.5-.8a5.6,5.6,0,0,1-1.3,2,5.74,5.74,0,0,1-2.07,1.26,8.15,8.15,0,0,1-2.63.41,7.7,7.7,0,0,1-3.9-1,6.6,6.6,0,0,1-2.6-2.8,9.64,9.64,0,0,1-.91-4.09v-.13h18.26Zm-4.65-1.85h-13.6v-.13a7.87,7.87,0,0,1,.84-3.15,7.14,7.14,0,0,1,2.45-2.73,6.74,6.74,0,0,1,3.74-1,6.5,6.5,0,0,1,3.45.91,6.29,6.29,0,0,1,2.3,2.47,7.6,7.6,0,0,1,.82,3.56Z"
      />
      <path
        fill={fill}
        d="M268.48,122.08a10.43,10.43,0,0,0,4.36.9,9.64,9.64,0,0,0,3.6-.61,7.88,7.88,0,0,0,2.46-1.53,7.05,7.05,0,0,0,1.43-1.88l0-.07h.41v3.52h4.63v-17a9.4,9.4,0,0,0-.95-4.51A7.32,7.32,0,0,0,282,98.27,9.66,9.66,0,0,0,278.8,97a15.84,15.84,0,0,0-3.25-.36,14.44,14.44,0,0,0-4.39.66,9.88,9.88,0,0,0-3.68,2.08,9,9,0,0,0-2.37,3.6l4.46,1a6.33,6.33,0,0,1,2-2.42,6.5,6.5,0,0,1,4.05-1.14,5.17,5.17,0,0,1,3.74,1.24,4.65,4.65,0,0,1,1.27,3.49v.11a1.55,1.55,0,0,1-.71,1.42,5.72,5.72,0,0,1-2.14.59c-1,.12-2.21.27-3.75.44a32.08,32.08,0,0,0-3.57.64,11.47,11.47,0,0,0-3.14,1.22,6.19,6.19,0,0,0-2.22,2.18,6.89,6.89,0,0,0-.82,3.52,7.5,7.5,0,0,0,1.13,4.2A7.32,7.32,0,0,0,268.48,122.08Zm1.2-9a4.61,4.61,0,0,1,2-1.29,12.25,12.25,0,0,1,2.68-.62l1.53-.21c.62-.09,1.25-.18,1.89-.29a13.07,13.07,0,0,0,1.72-.38,2.47,2.47,0,0,0,1-.46l.21-.21v3.61a5.73,5.73,0,0,1-.8,2.92,5.86,5.86,0,0,1-2.3,2.19,7.34,7.34,0,0,1-3.62.84,6.19,6.19,0,0,1-3.57-1,3.32,3.32,0,0,1-1.42-2.9A3.28,3.28,0,0,1,269.68,113.11Z"
      />
      <path
        fill={fill}
        d="M297.86,101.68a6.71,6.71,0,0,1,3.21-.76,11.81,11.81,0,0,1,1.56.11c.41.06.71.11.92.16V96.67l-.89-.07-1.11,0a7,7,0,0,0-4,1.18,6,6,0,0,0-2.43,3.2l0,.09h-.48V97h-4.59v25.45h4.75V106.84a5.84,5.84,0,0,1,.82-3.06A6,6,0,0,1,297.86,101.68Z"
      />
      <path
        fill={fill}
        d="M320.77,97.69a12,12,0,0,0-5.16-1.07,11.45,11.45,0,0,0-6.24,1.67,11.24,11.24,0,0,0-4.11,4.64,16.91,16.91,0,0,0,0,13.68,11.09,11.09,0,0,0,4.08,4.64,12.9,12.9,0,0,0,11.6.57,9.13,9.13,0,0,0,5.16-7.3h-4.63a5.74,5.74,0,0,1-1.11,2.33,5.23,5.23,0,0,1-2,1.55,6.75,6.75,0,0,1-6.4-.6,7.28,7.28,0,0,1-2.46-3.22,12.58,12.58,0,0,1-.87-4.9,12.22,12.22,0,0,1,.88-4.81,7.28,7.28,0,0,1,2.48-3.16,6.32,6.32,0,0,1,3.73-1.12,5.61,5.61,0,0,1,3.91,1.34,6.07,6.07,0,0,1,1.85,3.07h4.63a9.09,9.09,0,0,0-1.65-4.33A9.35,9.35,0,0,0,320.77,97.69Z"
      />
      <path
        fill={fill}
        d="M337.26,101.47a6.69,6.69,0,0,1,3.22-.77,5.42,5.42,0,0,1,4.15,1.61,6.39,6.39,0,0,1,1.48,4.48v15.62h4.76V106.18a11.63,11.63,0,0,0-1.09-5.3,7.35,7.35,0,0,0-3-3.19,9.44,9.44,0,0,0-4.57-1.07,8.57,8.57,0,0,0-4.78,1.2,7.36,7.36,0,0,0-2.72,3.24l0,.08h-.52V88.39h-4.69v34h4.75V107.27a7.3,7.3,0,0,1,.81-3.55A5.48,5.48,0,0,1,337.26,101.47Z"
      />
      <path
        fill={fill}
        d="M389.49,91a13.63,13.63,0,0,0-4.22-2.26,16.25,16.25,0,0,0-5.06-.77,14.89,14.89,0,0,0-7.85,2.1,14.59,14.59,0,0,0-5.44,6,20.54,20.54,0,0,0-2,9.37,20.55,20.55,0,0,0,2,9.36,14.72,14.72,0,0,0,5.43,6,14.94,14.94,0,0,0,7.87,2.1,15.67,15.67,0,0,0,5.18-.83,13.79,13.79,0,0,0,4.22-2.35,12.68,12.68,0,0,0,3-3.61,13.87,13.87,0,0,0,1.58-4.46l-5,0a8.12,8.12,0,0,1-1.14,2.85,8.48,8.48,0,0,1-2.05,2.17,9.14,9.14,0,0,1-2.69,1.33,10.67,10.67,0,0,1-3.13.45,9.85,9.85,0,0,1-5.33-1.5,10.06,10.06,0,0,1-3.74-4.4,19.19,19.19,0,0,1,0-14.19,10.29,10.29,0,0,1,3.73-4.42,9.8,9.8,0,0,1,5.36-1.51,10.42,10.42,0,0,1,3.14.46,8.83,8.83,0,0,1,2.69,1.35,8.4,8.4,0,0,1,2,2.2,8.06,8.06,0,0,1,1.12,2.88h5a14,14,0,0,0-1.63-4.72A12.72,12.72,0,0,0,389.49,91Z"
      />
      <path
        fill={fill}
        d="M414.82,98.26a12.69,12.69,0,0,0-12.48,0,11.07,11.07,0,0,0-4.14,4.6,15.46,15.46,0,0,0-1.48,6.94,15.28,15.28,0,0,0,1.48,6.9,11.11,11.11,0,0,0,4.14,4.59,12.69,12.69,0,0,0,12.48,0A11.11,11.11,0,0,0,419,116.7a15.31,15.31,0,0,0,1.47-6.9,15.49,15.49,0,0,0-1.47-6.94A11.07,11.07,0,0,0,414.82,98.26Zm.06,16.1a7.53,7.53,0,0,1-2.32,3.35,6.86,6.86,0,0,1-7.95,0,7.39,7.39,0,0,1-2.33-3.35,14.33,14.33,0,0,1,0-9.15,7.45,7.45,0,0,1,2.33-3.37,6.77,6.77,0,0,1,7.95,0,7.59,7.59,0,0,1,2.32,3.37,14.33,14.33,0,0,1,0,9.15Z"
      />
      <path
        fill={fill}
        d="M444.9,122.41V97h-4.77v14.91a6.59,6.59,0,0,1-.93,3.69,6.11,6.11,0,0,1-2.37,2.16,6.31,6.31,0,0,1-2.86.72,5.11,5.11,0,0,1-3.88-1.6,6,6,0,0,1-1.48-4.26V97h-4.76v16.22a11.74,11.74,0,0,0,1.07,5.28,7.36,7.36,0,0,0,2.93,3.2,8.46,8.46,0,0,0,4.31,1.08,8.16,8.16,0,0,0,4.77-1.34,7.9,7.9,0,0,0,2.8-3.37l0-.07h.47v4.45Z"
      />
      <path
        fill={fill}
        d="M457.18,101.47a6.36,6.36,0,0,1,3.15-.77,5.3,5.3,0,0,1,4.09,1.63,6.34,6.34,0,0,1,1.49,4.46v15.62h4.75V106.18a11.77,11.77,0,0,0-1.07-5.28,7.41,7.41,0,0,0-3-3.2A9.25,9.25,0,0,0,462,96.62a8.51,8.51,0,0,0-4.72,1.22,7.25,7.25,0,0,0-2.74,3.22l0,.08H454V97h-4.55v25.45h4.75V107.27a7.42,7.42,0,0,1,.8-3.55A5.42,5.42,0,0,1,457.18,101.47Z"
      />
      <path
        fill={fill}
        d="M491,97.69a12.07,12.07,0,0,0-5.17-1.07,11.45,11.45,0,0,0-6.24,1.67,11.15,11.15,0,0,0-4.1,4.64,15.49,15.49,0,0,0-1.46,6.87,15.76,15.76,0,0,0,1.42,6.81,11.16,11.16,0,0,0,4.08,4.64,11.72,11.72,0,0,0,6.35,1.68,11.88,11.88,0,0,0,5.26-1.11,9.19,9.19,0,0,0,5.16-7.3h-4.63a5.75,5.75,0,0,1-1.12,2.33,5.23,5.23,0,0,1-2,1.55,6.75,6.75,0,0,1-6.4-.6,7.35,7.35,0,0,1-2.46-3.22,12.78,12.78,0,0,1-.86-4.9,12,12,0,0,1,.88-4.81,7.28,7.28,0,0,1,2.48-3.16,6.29,6.29,0,0,1,3.72-1.12,5.59,5.59,0,0,1,3.91,1.34,6,6,0,0,1,1.85,3.07h4.63a9,9,0,0,0-1.64-4.33A9.5,9.5,0,0,0,491,97.69Z"
      />
      <path
        fill={fill}
        d="M502,87a3,3,0,0,0-2.14.84,2.74,2.74,0,0,0,0,4,3.16,3.16,0,0,0,4.29,0,2.74,2.74,0,0,0,0-4A3,3,0,0,0,502,87Z"
      />
      <rect fill={fill} x="499.55" y="96.96" width="4.75" height="25.45" />
      <rect fill={fill} x="508.75" y="88.26" width="5" height="34.27" />
      <path fill={fill} d="M513.88,122.66h-5.25V88.14h5.25Zm-5-.25h4.75v-34h-4.75Z" />
    </svg>
  );
};

export default EtagLogoEng;

import React, { FC, ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IS_DEMO } from 'shared/constants/appConstants';
import { EnvironmentPanel } from 'components';
import PortalApp from './PortalApp';

const AppContents: FC = (): ReactElement => {
  return (
    <>
      {IS_DEMO && <EnvironmentPanel />}
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <PortalApp />
      </BrowserRouter>
    </>
  );
};

export default AppContents;

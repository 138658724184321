export const languages: Language[] = [
  { code: 'et', label: 'est', longLabel: 'Estonian' },
  { code: 'en', label: 'eng', longLabel: 'English' },
];

export type Language = {
  code: 'et' | 'en';
  label: string;
  longLabel: string;
};

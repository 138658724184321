import React, { FC, ReactElement } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, Row } from 'reactstrap';
import DoneIcon from 'components/Icons/Done/DoneIcon';
import clsx from 'clsx';
import { Language, languages } from 'shared/constants/languages';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import EtisLogoPositive from 'components/Icons/Logo/EtisLogoPositive';
import { routeTermOfUse } from 'routes';
import PlanetIcon from 'components/Icons/Planet/PlanetIcon';
import { Field, Form, Formik } from 'formik';
import { Cookies } from 'react-cookie-banner/lib';

type CookiesPrivacyModalProps = {
  showExportModal: boolean;
  onAccept: () => any;
  onReject: () => any;
};

export const CookiesPrivacyModal: FC<CookiesPrivacyModalProps> = ({
  showExportModal,
  onAccept,
}): ReactElement => {
  const cookieMaxAgeLimit = 1707109200; // - 400 days
  const { i18n, t } = useTranslation();
  const [languageSwitchOpened, setLanguageSwitchOpened] = React.useState(false);

  const currentLanguageCode = i18n.language;
  const currentLanguage = languages.filter(({ code }) => code === currentLanguageCode);

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const changeLanguage = (code: string) => {
    onLanguageChange(code);
    setLanguageSwitchOpened(false);
  };

  const setCookieValues = (value: any) => {
    const cookies = new Cookies();
    cookies.set('statistics-cookies', value.statistics, { path: '/', maxAge: cookieMaxAgeLimit });
    onAccept();
    // changing the cookies settings for google analytics is valid only after the page is reloaded
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out
    window.location.reload();
  };

  const languageLinkHtml = (language: Language, hasChildren?: boolean) => (
    <span
      key={language.code}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => changeLanguage(language.code)}
      className={clsx(
        'nav-link link-dark pointer language-item',
        language.code === currentLanguageCode && 'active',
      )}
      aria-label={language.longLabel}
    >
      {language.longLabel}{' '}
      {language.code === currentLanguageCode && <DoneIcon fill="#2343E6" width={24} height={24} />}
    </span>
  );

  const languageSwitcher = () => (
    <Dropdown
      isOpen={languageSwitchOpened}
      toggle={() => setLanguageSwitchOpened(!languageSwitchOpened)}
      className="language-switcher"
    >
      <DropdownToggle
        caret
        nav
        aria-label="Change language"
        className="nav-link link-dark dropdown-toggle language-toggle"
      >
        <span className="language-switcher d-flex align-content-center">
          <span className="m-l-4 font-16 text-uppercase">{currentLanguage[0].label}</span>
          <PlanetIcon height={22} width={22} />
        </span>
      </DropdownToggle>
      <DropdownMenu right>
        {languages.map((language, index) => {
          return languageLinkHtml(language);
        })}
      </DropdownMenu>
    </Dropdown>
  );
  return (
    <>
      <Modal
        show={showExportModal}
        onHide={() => {}}
        size="xl"
        className="export-modal"
        backdrop="static"
      >
        <EtisLogoPositive />
        <span className="cookie-langugage">{languageSwitcher()}</span>
        <Modal.Header className="m-t-36">
          <Modal.Title as="h2">{t('CookiesPrivacyOptions')}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            termsOfUse: true,
            essential: true,
            statistics: true,
          }}
          onSubmit={(values) => {
            setCookieValues(values);
          }}
        >
          {({}) => (
            <Form role="form" className="form-horizontal">
              <Modal.Body>
                {t('CookieText')}
                <Row role="group" aria-labelledby="checkbox-group" className="pl-2">
                  <div className="form-check mt-4">
                    <Field
                      id="cookie_toggle1"
                      type="checkbox"
                      name="termsOfUse"
                      className="form-check-input"
                      disabled
                    />
                    <label htmlFor="cookie_toggle1" className="form-check-label">
                      <strong>{t('ETIS_termsOfUse')}</strong>
                      <a rel="noreferrer" href={`${routeTermOfUse}`}>
                        {' '}
                        {t('ReadMore')}
                      </a>
                    </label>
                  </div>
                  <div className="form-check mt-4">
                    <Field
                      id="cookie_toggle2"
                      type="checkbox"
                      name="essential"
                      className="form-check-input"
                      disabled
                    />
                    <label htmlFor="cookie_toggle2" className="form-check-label">
                      <strong>{t('EssentialCookies')} </strong>
                      {t('EssentialCookiesText')}
                    </label>
                  </div>
                  <div className="form-check mt-4">
                    <Field
                      id="cookie_toggle3"
                      type="checkbox"
                      name="statistics"
                      className="form-check-input"
                    />
                    <label htmlFor="cookie_toggle3" className="form-check-label">
                      <strong>{t('StatisticsCookies')} </strong>
                      {t('StatisticsCookiesText')}
                    </label>
                  </div>
                </Row>
              </Modal.Body>
              <div className="d-flex justify-content-end m-t-36">
                <button type="submit" className="btn btn-primary">
                  {t('CookiePolicyIAgree')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const DoneIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 24,
  height = 24,
  fill = '#000000',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="OVERVIEW" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Icons"
          transform="translate(-756.000000, -291.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Icon" transform="translate(756.000000, 291.000000)">
            <path
              d="M19.85,7.25 L9.25,17.85 C9.1561167,17.9446563 9.02831877,17.9978992 8.895,17.9978992 C8.76168123,17.9978992 8.6338833,17.9446563 8.54,17.85 L3.15,12.46 C3.05534367,12.3661167 3.00210084,12.2383188 3.00210084,12.105 C3.00210084,11.9716812 3.05534367,11.8438833 3.15,11.75 L3.85,11.05 C3.9438833,10.9553437 4.07168123,10.9021008 4.205,10.9021008 C4.33831877,10.9021008 4.4661167,10.9553437 4.56,11.05 L8.89,15.38 L18.44,5.83 C18.6378137,5.63821671 18.9521863,5.63821671 19.15,5.83 L19.85,6.54 C19.9446563,6.6338833 19.9978992,6.76168123 19.9978992,6.895 C19.9978992,7.02831877 19.9446563,7.1561167 19.85,7.25 Z"
              id="Icon-color"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DoneIcon;
